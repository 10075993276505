import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface FeedbackFormProps {
   userId: number;
  onClose: () => void;
  isViewMode: boolean;
}

const feedbackCategories = [
    "Behaviors",
    "Performs in a dependable manner",
    "Cooperates with co-workers and supervisors",
    "Shows interest in work",
    "Learns quickly",
    "Shows initiative",
    "Produces high quality work",
    "Accepts responsibility",
    "Accepts criticism",
    "Demonstrates organizational skills",
    "Uses technical knowledge and expertise",
    "Shows good judgment",
    "Demonstrates creativity/originality",
    "Analyzes problems effectively",
    "Is self-reliant",
    "Communicates well",
    "Writes effectively",
    "Has a professional attitude",
    "Gives a professional appearance",
    "Is punctual",
    "Uses time effectively",
    "Overall Experience"
];


const FeedbackFormIndustry: React.FC<FeedbackFormProps> = ({ userId, onClose, isViewMode }) => {
    const [feedback, setFeedback] = useState<string[]>(new Array(feedbackCategories.length).fill(''));

  useEffect(() => {
    if (isViewMode) {
      // Fetch feedback data for view-only mode
      axios.get(`/api/industry-feedback/${userId}`)
        .then(response => setFeedback([
         response.data.behaviors,
response.data.dependable,
response.data.cooperates,
response.data.interestInWork,
response.data.learnsQuickly,
response.data.initiative,
response.data.highQualityWork,
response.data.responsibility,
response.data.criticism,
response.data.organizationalSkills,
response.data.technicalKnowledge,
response.data.judgment,
response.data.creativity,
response.data.problemSolving,
response.data.selfReliant,
response.data.communication,
response.data.writing,
response.data.professionalAttitude,
response.data.professionalAppearance,
response.data.punctuality,
response.data.timeManagement,
response.data.overallExperience,

        ]))
        .catch(error => console.error("Error loading feedback:", error));
    }
  }, [userId, isViewMode]);

  const handleFeedbackChange = (index: number, value: string) => {
    if (isViewMode) return; // Prevent editing if in view mode
    const updatedFeedback = [...feedback];
    updatedFeedback[index] = value;
    setFeedback(updatedFeedback);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!isViewMode) {
      try {
        const feedbackData = {
          userId,
         behaviors: feedback[0],
dependable: feedback[1],
cooperates: feedback[2],
interestInWork: feedback[3],
learnsQuickly: feedback[4],
initiative: feedback[5],
highQualityWork: feedback[6],
responsibility: feedback[7],
criticism: feedback[8],
organizationalSkills: feedback[9],
technicalKnowledge: feedback[10],
judgment: feedback[11],
creativity: feedback[12],
problemSolving: feedback[13],
selfReliant: feedback[14],
communication: feedback[15],
writing: feedback[16],
professionalAttitude: feedback[17],
professionalAppearance: feedback[18],
punctuality: feedback[19],
timeManagement: feedback[20],
overallExperience: feedback[21],

        };
        const response = await axios.post('/api/industry-feedback/save', feedbackData);
        if (response.status === 200) {
          console.log('Feedback submitted successfully');
          onClose();
        } else {
          console.error('Failed to submit feedback');
        }
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    }
  };
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-center text-lg font-semibold mb-4">
          {isViewMode ? "View Feedback" : "Give Your Feedback"}
        </h2>
                <div className="max-h-[70vh] overflow-y-auto scrollbar-hide pr-6">
                    {feedbackCategories.map((category, index) => (
                        <div key={index}>
                            <div className="flex justify-between">
                                <h3 className="font-semibold">{index + 1}. {category}</h3>
                            </div>
                            <div className="flex justify-between mb-5">
                                {["Excellent", "Very Good", "Satisfactory", "Not Satisfactory"].map(option => (
                                    <label key={option} className="flex items-center">
                                        <input
                                            type="radio"
                                            value={option}
                                            checked={feedback[index] === option}
                                            onChange={() => handleFeedbackChange(index, option)}
                                            className="mr-2 ml-2"
                                            disabled={isViewMode}
                                        />
                                        {option}
                                    </label>
                                ))}
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-center">
                     {!isViewMode && (
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg mr-4"
              >
                Submit
              </button>
            )}
            <button
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded-lg"
            >
              {isViewMode ? "Close" : "Cancel"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackFormIndustry;

