import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/leave.css';

const LeaveRequestForm: React.FC = () => {
  const [formData, setFormData] = useState({
    leaveType: 'Sick Leave',
    fromDate: '',
    toDate: '',
    description: '',
    numberOfDays: '',
  });

  const [log, setLog] = useState({ userId: '' });
  const [error, setError] = useState('');

  // Fetch userId based on IIMSTC_ID from localStorage
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setLog((prevLog) => ({
            ...prevLog,
            userId: response.data.userId,
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID:', error);
        setError('Failed to fetch user ID. Please try again.');
      }
    };

    fetchUserId();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!log.userId) {
      setError('User ID not available. Cannot submit the request.');
      return;
    }

    try {
      const response = await axios.post('/api/leave/leaves', {
        userId: log.userId,  // include the fetched userId in the request
        ...formData,
      });
      console.log('Leave Request Submitted:', response.data);
      
      // Display success alert
      window.alert('Leave request submitted successfully!');
      
      // Handle success, reset form if needed
      setFormData({
        leaveType: 'Sick Leave',
        fromDate: '',
        toDate: '',
        description: '',
        numberOfDays: '',
      });
      
    } catch (error) {
      console.error('Error submitting leave request:', error);
      setError('Failed to submit leave request. Please try again.');
    }
  };

  return (
    <div className="leave-form-container">
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <h2 className="leave-form-header">Leave Request Form</h2>

        <div className="leave-input-container">
          <label htmlFor="leaveType">Type of Leave:</label>
          <select name="leaveType" value={formData.leaveType} onChange={handleChange}>
            <option value="Sick Leave">Sick Leave</option>
            <option value="Casual Leave">Casual Leave</option>
            <option value="Annual Leave">Annual Leave</option>
          </select>
        </div>

        <div className="leave-input-container">
          <label htmlFor="fromDate">From Date:</label>
          <input type="date" name="fromDate" value={formData.fromDate} onChange={handleChange} />
        </div>

        <div className="leave-input-container">
          <label htmlFor="toDate">To Date:</label>
          <input type="date" name="toDate" value={formData.toDate} onChange={handleChange} />
        </div>

        <div className="leave-input-container">
          <label htmlFor="numberOfDays">Number of Days:</label>
          <input type="number" name="numberOfDays" value={formData.numberOfDays} onChange={handleChange} />
        </div>

        <div className="leave-input-container">
          <label htmlFor="description">Description:</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            placeholder="Provide a brief description of your leave request"
          />
        </div>

        <button type="submit" className="leave-submit-button">Submit Request</button>
      </form>
    </div>
  );
};

export default LeaveRequestForm;
