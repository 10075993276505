import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './css-styles/form.css';
import axios from 'axios';

interface FormFields {
  email: string;
  name: string;
  dob: string;
  otp: string;
  address: string;
  collegeId: string;
  degreeId: string;
  branchId: string;
  passcode: string;
  degreeStatusId: string;
  university: string;
  usn: string;
  aadharNo: string;
  gender: string;
  semester: string;
  phoneNumber: string;
  tenthBoard: string;
  tenthYear: number | null;
  tenthPercentage: number | null;
  twelfthBoard: string;
  twelfthYear: number | null;
  twelfthPercentage: number | null;
  degreeUniversity: string;
  degreeLastSemResult: string;
  passportPhoto: File | null;
  aadharProof:File | null;
  tenthDocumentURL: File | null;
  twelfthDocumentURL: File | null;
  degreeResultFileURL: File | null;
  resumeURL:File | null;
  coverletterURL: File | null;
  videoURL: File | null;
}

const Form = () => {
  const [formFields, setFormFields] = useState<FormFields>({
    email: '',
    name: '',
    dob: '',
    otp: '',
    address: '',
    collegeId: '',
    degreeId: '',
    branchId: '',
    passcode: '',
    degreeStatusId: '',
    university: '',
    usn: '',
    aadharNo: '',
    gender: '',
    semester: '',
    phoneNumber: '',
    tenthBoard: '',
    tenthYear: null,
    tenthPercentage: null,
    twelfthBoard: '',
    twelfthYear: null,
    twelfthPercentage: null,
    degreeUniversity: '',
    degreeLastSemResult: '',
    passportPhoto: null,
    aadharProof: null,
    tenthDocumentURL: null,
    twelfthDocumentURL: null,
    degreeResultFileURL: null,
    resumeURL: null,
    coverletterURL: null,
    videoURL: null,
  });

  const [validity, setValidity] = useState({
    dob: true,
  });

  const semesters = [
    { id: '1', name: 'Semester 1' },
    { id: '2', name: 'Semester 2' },
    { id: '3', name: 'Semester 3' },
    { id: '4', name: 'Semester 4' },
    { id: '5', name: 'Semester 5' },
    { id: '6', name: 'Semester 6' },
    { id: '7', name: 'Semester 7' },
    { id: '8', name: 'Semester 8' },
  ];

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const [fileNames, setFileNames] = useState({
    passportPhoto: '',
    aadharProof: '',
    tenthDocumentURL: '',
    twelfthDocumentURL: '',
    degreeResultFileURL: '',
    resumeURL: '',
    coverletterURL: '',
    videoURL: '',
  });

  const [loadingCollegeName, setLoadingCollegeName] = useState(false);

  const fetchCollegeName = async (passcode: string) => {
    setLoadingCollegeName(true);
    try {
      const response = await axios.get(`/api/college/passcode`, { params: { passcode } });

      const data = response.data;

      if (data.name) {
        setFormFields((prevFields) => ({ ...prevFields, collegeId: data.name }));
      } else {
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.error('Error fetching college name:', error);
      setErrorMessage('Server error');
    } finally {
      setLoadingCollegeName(false);
    }
  };

 const handleChange = (
  e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
) => {
  const { name, value } = e.target;

  if (e.target instanceof HTMLInputElement && e.target.type === 'file') {
    const files = e.target.files;
    if (files) {
      setFormFields((prevFields) => ({
        ...prevFields,
        [name]: files[0], // Store the File object directly
      }));

      // Update the corresponding file name
      setFileNames((prevNames) => ({
        ...prevNames,
        [name]: files[0].name, // Store the file name
      }));
    }
  } else if (e.target.type === 'number') {
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value ? Number(value) : null,
    }));
  } else {
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  }
};
const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const formData = new FormData();
  Object.keys(formFields).forEach((key) => {
    const field = formFields[key as keyof FormFields];
    if (field instanceof File) {
      formData.append(key, field); // Append file directly
    } else {
      formData.append(key, String(field)); // Append other fields as strings
    }
  });

  try {
    const response = await fetch('/api/normal/submit', {
      method: 'POST',
      body: formData,
    });

    const data = await response.json();

    if (data.message) {
      navigate('/success');
    } else {
      setErrorMessage(data.error);
    }
  } catch (error) {
    console.error('Error submitting form:', error);
    setErrorMessage('Server error');
  }
};
  return (
    <div className="form-background">
    <form onSubmit={handleSubmit} id="multiPartForm" className="reg-form-container">
    <h2 className="reg-form-heading">REGISTRATION</h2>
      {/* Personal Details Section */}
      <fieldset className="reg-form-section">
        <legend className="reg-form-section-title">Personal Details[as per AADHAAR]</legend>
        <div className="reg-form-group">
          <label htmlFor="name" className="reg-form-label">Name</label>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Name"
            className="reg-form-input"
            value={formFields.name}
            onChange={handleChange}
            style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
        
        <div className="reg-form-group">
          <label htmlFor="gender" className="reg-form-label">Gender</label>
          <select
            id="gender"
            name="gender"
            className="reg-form-input"
            value={formFields.gender}
            onChange={handleChange}
            style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="reg-form-group">
          <label htmlFor="phoneNumber" className="reg-form-label">Phone Number</label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            type="text" value={formFields.phoneNumber}
      onChange={handleChange}
      placeholder="Phone Number"
      className="reg-form-input"
      style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
      required
    />
        </div>
        <div className="reg-form-group">
  <label htmlFor="email" className="reg-form-label">Email</label>
  <input
    id="email"
    name="email"
    type="email"
    className="reg-form-input"
    value={formFields.email}
    onChange={handleChange}
    style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
  />
</div>
<div className="reg-form-group">
            <label htmlFor="dob" className="reg-form-label">Date of Birth</label>
            <input
              id="dob"
              name="dob"
              type="date"
              className="reg-form-input"
              value={formFields.dob}
        onChange={handleChange}

       style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
        required
      />
      {!validity.dob && (
        <div style={{ color: 'red', marginTop: '8px' }}>{errorMessage}</div>
      )}

          </div>
        <div className="reg-form-group">
          <label htmlFor="address" className="reg-form-label">Address</label>
          <textarea
            id="address"
            name="address"
            placeholder="Address"
            className="reg-form-input"
            value={formFields.address}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => handleChange(e)}

           style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
      </fieldset>

      {/* Educational Details Section */}
      <fieldset className="reg-form-section">
        <legend className="reg-form-section-title">Educational Details</legend>
<div className="reg-form-group">
  <label htmlFor="passcode" className="reg-form-label">Passcode</label>
  <input
    id="passcode"
    name="passcode"
    type="text"
    placeholder="Passcode"
    className="reg-form-input"
    value={formFields.passcode}
    onChange={handleChange}
    onBlur={(e) => {
      if (e.target.value) {
        fetchCollegeName(e.target.value);
      } else {
        setFormFields((prevFields) => ({ ...prevFields, collegeId: '' }));
      }
    }}
    style={{
      padding: '8px',
      color: 'black',
      borderRadius: '4px',
    }}
    required
  />
  {loadingCollegeName && (
    <div>Loading college name...</div>
  )}
</div>        
<div className="reg-form-group">
  <label htmlFor="collegeId" className="reg-form-label">College</label>
  <input
    id="collegeId"
    name="collegeId"
    type="text"
    placeholder="College"
    className="reg-form-input"
    value={formFields.collegeId}
    onChange={handleChange}
    style={{
      padding: '8px',
      color: 'black',
      borderRadius: '4px',
    }}
    required
  />
</div>

<div className="reg-form-group">
  <label htmlFor="degreeId" className="reg-form-label">Degree</label>
  <input
    id="degreeId"
    name="degreeId"
    type="text" // Change this to a suitable type if needed
    placeholder="Enter Degree ID"
    className="reg-form-input"
    value={formFields.degreeId}
    onChange={handleChange}
    style={{
      padding: '8px',
      color: formFields.degreeId === "" ? 'black' : 'black', // Adjust logic if necessary
      borderRadius: '4px',
    }}
    required
  />
</div>

<div className="reg-form-group">
  <label htmlFor="branchId" className="reg-form-label">Branch</label>
  <input
    id="branchId"
    name="branchId"
    type="text" // Change this to a suitable type if needed
    placeholder="Enter Branch ID"
    className="reg-form-input"
    value={formFields.branchId}
    onChange={handleChange}
    style={{
      padding: '8px',
      color: formFields.branchId === "" ? 'black' : 'black', // Adjust logic if necessary
      borderRadius: '4px',
    }}
    required
  />
</div>

<div className="reg-form-group">
  <label htmlFor="degreeStatusId" className="reg-form-label">Degree Status</label>
  <input
    id="degreeStatusId"
    name="degreeStatusId"
    type="text" // Change this to a suitable type if needed
    placeholder="Enter Degree Status ID"
    className="reg-form-input"
    value={formFields.degreeStatusId}
    onChange={handleChange}
    style={{
      padding: '8px',
      color: formFields.degreeStatusId === "" ? 'black' : 'black', // Adjust logic if necessary
      borderRadius: '4px',
    }}
    required
  />
</div>

        <div className="reg-form-group">
          <label htmlFor="university" className="reg-form-label">University</label>
          <input
            id="university"
            name="university"
            type="text"
            placeholder="University"
            className="reg-form-input"
            value={formFields.university}
            onChange={handleChange}
            style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
        <div className="reg-form-group">
          <label htmlFor="usn" className="reg-form-label">USN</label>
          <input
            id="usn"
            name="usn"
            type="text"
            placeholder="USN"
            value={formFields.usn}
            onChange={handleChange}
            className="reg-form-input"
             style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
      required
    />
        </div>
        <div className="reg-form-group">
          <label htmlFor="aadharNo" className="reg-form-label">Aadhar Number</label>
          <input
            id="aadharNo"
            name="aadharNo"
            type="text"
            placeholder="Aadhar Number"
            className="reg-form-input"
            value={formFields.aadharNo}
            onChange={handleChange}
           style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          />
        </div>
        <div className="reg-form-group">
          <label htmlFor="semester" className="reg-form-label">Semester</label>
          <select
            id="semester"
            name="semester"
            className="reg-form-input"
            value={formFields.semester}
            onChange={handleChange}
           style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
            required
          >
            <option value="">Select Semester</option>
            {semesters.map((semester) => (
              <option key={semester.id} value={semester.id}>
                {semester.name}
              </option>
            ))}
          </select>
        </div>
       
         <div className="reg-form-group">
            <label htmlFor="tenthBoard" className="reg-form-label">10th Board</label>
            <input id="tenthBoard" name="tenthBoard" type="text" placeholder="10th Board" className="reg-form-input"  value={formFields.tenthBoard}
            onChange={handleChange}
              style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
           
            required
          />
          </div>
        <div className="reg-form-group">
  <label htmlFor="tenthYear" className="reg-form-label">10th Passing Year</label>
  <input
    id="tenthYear"
    name="tenthYear"
    type="text" // or "number" if you expect numeric input
    placeholder="10th Passing Year"
    className="reg-form-input"
    value={formFields.tenthYear !== null ? String(formFields.tenthYear) : ''} // Convert to string or use an empty string
    onChange={handleChange}
    style={{
      padding: '8px',
      color: 'black', // Adjust color logic if needed
      borderRadius: '4px',
    }}
    required
  />
</div>

         <div className="reg-form-group">
            <label htmlFor="tenthPercentage" className="reg-form-label">`10th Percentage</label>
            <input 
  id="tenthPercentage" 
  name="tenthPercentage" 
  type="text" 
  placeholder="10th Percentage" 
  className="reg-form-input"  
  value={formFields.tenthPercentage !== null ? String(formFields.tenthPercentage) : ''} 
  onChange={handleChange}
  style={{
    padding: '8px',
    color: formFields.degreeId === "" ? 'black' : 'black',
    borderRadius: '4px',
  }}
  required
/>

          </div>
        <div className="reg-form-group">
            <label htmlFor="twelfthBoard" className="reg-form-label">12th Board</label>
            <input id="twelfthBoard" name="twelfthBoard" type="text" placeholder="12th Board" className="reg-form-input"  value={formFields.twelfthBoard}
            onChange={handleChange}
              
              style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
           
            required
          />
          </div>
        <div className="reg-form-group">
            <label htmlFor="twelfthYear" className="reg-form-label">12th Passing Year</label>
            <input 
  id="twelfthYear" 
  name="twelfthYear" 
  type="text" 
  placeholder="12th Passing Year" 
  className="reg-form-input"  
  value={formFields.twelfthYear !== null ? String(formFields.twelfthYear) : ''} // Keep this one
  onChange={handleChange}
  style={{
    padding: '8px',
    color: 'black', 
    borderRadius: '4px',
  }}
  required
/>

          </div>
        <div className="reg-form-group">
            <label htmlFor="twelfthPercentage" className="reg-form-label">12th Percentage</label>
            <input 
  id="twelfthPercentage" 
  name="twelfthPercentage" 
  type="text" 
  placeholder="12th Percentage" 
  className="reg-form-input"  
  value={formFields.twelfthPercentage !== null ? String(formFields.twelfthPercentage) : ''} // Keep this one
  onChange={handleChange}
  style={{
    padding: '8px',
    color: formFields.degreeId === "" ? 'black' : 'black',
    borderRadius: '4px',
  }}
  required
/>

          </div>
        <div className="reg-form-group">
            <label htmlFor="degreeUniversity" className="reg-form-label">Degree University</label>
            <input id="degreeUniversity" name="degreeUniversity" type="text" placeholder="Degree University" className="reg-form-input"  value={formFields.degreeUniversity}
            onChange={handleChange}
              style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
           
            required
          />
          </div>
        <div className="reg-form-group">
            <label htmlFor="degreeLastSemResult" className="reg-form-label">CGPA</label>
            <input id="degreeLastSemResult" name="degreeLastSemResult" type="text" placeholder="CGPA" className="reg-form-input"  value={formFields.degreeLastSemResult}
            onChange={handleChange}
              style={{
               
                padding: '8px',
                color: formFields.degreeId === "" ? 'black' : 'black',
                borderRadius: '4px',
              }}
           
            required
          />
          </div>
      </fieldset>

      {/* Documents Section */}
      <fieldset className="reg-form-section">
        <legend className="reg-form-section-title">Documents</legend>
        <div className="reg-form-group">
          <label htmlFor="passportPhoto" className="reg-form-label text-xs">
      <span style={{ color: 'white', fontSize: '16px' }}>Photo</span>
      <br />
      <span style={{ color: 'red' }}> [Max File size: 50 KB]</span>
    </label>
    <input
      id="passportPhoto"
      name="passportPhoto"
      type="file"
      className="reg-form-input"
      onChange={handleChange}
      style={{ color: 'black' }}
    />
           
        </div>
        <div className="reg-form-group">
            <label htmlFor="aadharProof" className="reg-form-label text-xs">
      <span style={{ color: 'white', fontSize: '16px' }}>Aadhar/Passport Proof</span>
      <br />
      <span style={{ color: 'red' }}> [Max File size: 50 KB]</span>
    </label>
    <input
      id="aadharProof"
      name="aadharProof"
      type="file"
      className="reg-form-input"
      onChange={handleChange}
      style={{ color: 'black' }}
    />
          
        </div>
         <div className="reg-form-group">
  <label htmlFor="tenthDocumentURL" className="reg-form-label">10th Marks Card</label>
  <input
    id="tenthDocumentURL"
    name="tenthDocumentURL"
    type="file"
    className="reg-form-input"
    onChange={handleChange}
    style={{ color: 'black' }}
            required
           
          />
            
        </div>
       <div className="reg-form-group">
  <label htmlFor="twelfthDocumentURL" className="reg-form-label">12th Marks Card</label>
  <input
    id="twelfthDocumentURL"
    name="twelfthDocumentURL"
    type="file"
    className="reg-form-input"
    onChange={handleChange}
    style={{ color: 'black' }}
    required
  />
</div>
<div className="reg-form-group">
  <label htmlFor="degreeResultFileURL" className="reg-form-label">Degree Result File:</label>
  <input
    id="degreeResultFileURL"
    name="degreeResultFileURL"
    type="file"
    className="reg-form-input"
    onChange={handleChange}
    style={{ color: 'black' }}
    required
  />
</div>
<div className="reg-form-group">
  <label htmlFor="resumeURL" className="reg-form-label">Resume:</label>
  <input
    id="resumeURL"
    name="resumeURL"
    type="file"
    className="reg-form-input"
    onChange={handleChange}
    style={{ color: 'black' }}
  />
</div>
<div className="reg-form-group">
  <label htmlFor="coverletterURL" className="reg-form-label">Cover Letter:</label>
  <input
    id="coverletterURL"
    name="coverletterURL"
    type="file"
    className="reg-form-input"
    onChange={handleChange}
    style={{ color: 'black' }}
  />
</div>
<div className="reg-form-group">
  <label htmlFor="videoURL" className="reg-form-label">1-2 Mins Self Intro Video:</label>
  <input
    id="videoURL"
    name="videoURL"
    type="file"
    className="reg-form-input"
    onChange={handleChange}
    style={{ color: 'black' }}
  />
</div>
      </fieldset>

      {/* Submit Button */}
      <div className="reg-btn-container">
      <button type="submit" className="reg-form-submit">Submit</button>
     <button
      type="button"
      id="go-back-btn"
      className="reg-button-go-back"
      onClick={() => navigate('/')}
    >
      Go Back
    </button>
        </div>

        <div className="reg-additional-links">
         <p className="reg-login-link">
  Already have an account? <Link to="/log">Login here</Link>
</p>
          <img src="./image/log.webp" alt="Logo" className="reg-login-legend-logo" />
        </div>
    </form>
  </div>  
  );
};

export default Form;
