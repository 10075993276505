import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import styles from '../styles/StudentFeedback.module.css'; // Import the stylesheet

// Define the Likert scale options
const likertOptions = [
  'Strongly Agree',
  'Agree',
  'No Opinion',
  'Disagree',
  'Strongly Disagree',
];

// Define the types for the feedback form data
interface StudentFeedbackData {
  userId: string;
  careerFieldExplorationId: string;
  classroomTheoryApplicationId: string;
  decisionMakingSkillsId: string;
  workWorldKnowledgeId: string;
  communicationSkillsId: string;
  leadershipSkillsId: string;
  ethicalSensitivityId: string;
  confidenceInNewSituationsId: string;
  interpersonalSkillsId: string;
  responsibilityHandlingId: string;
  selfDiscoveryId: string;
  newInterestsId: string;
  careerGoalsClarificationId: string;
  futureEmploymentContactsId: string;
  equipmentUsageId: string;
  facultyCoordinatorMentorship: string;
  goalsAccomplishment: string;
  areasOfImprovement: string;
  significantAccomplishment: string;
  dislikes: string;
  overallExperienceRating: string;
  internshipImprovementSuggestions: string;
}

// Define the type for individual feedback entries
interface FeedbackEntry extends StudentFeedbackData {
  id: number;
}

const StudentFeedback: React.FC = () => {
  const [formData, setFormData] = useState<StudentFeedbackData>({
    userId: '',
    careerFieldExplorationId: '',
    classroomTheoryApplicationId: '',
    decisionMakingSkillsId: '',
    workWorldKnowledgeId: '',
    communicationSkillsId: '',
    leadershipSkillsId: '',
    ethicalSensitivityId: '',
    confidenceInNewSituationsId: '',
    interpersonalSkillsId: '',
    responsibilityHandlingId: '',
    selfDiscoveryId: '',
    newInterestsId: '',
    careerGoalsClarificationId: '',
    futureEmploymentContactsId: '',
    equipmentUsageId: '',
    facultyCoordinatorMentorship: '',
    goalsAccomplishment: '',
    areasOfImprovement: '',
    significantAccomplishment: '',
    dislikes: '',
    overallExperienceRating: '',
    internshipImprovementSuggestions: '',
  });

  const [feedback, setFeedback] = useState<FeedbackEntry[]>([]);

  useEffect(() => {
    // Fetch existing feedback entries
    axios.get<FeedbackEntry[]>('/api/student-feedback')
      .then(response => setFeedback(response.data))
      .catch(error => console.error('Error fetching feedback:', error));
  }, []);

  // Fetch userId based on IIMSTC_ID in localStorage
  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setFormData((prevFormData) => ({
            ...prevFormData,
            userId: response.data.userId,
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID', error);
      }
    };

    fetchUserId();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    try {
      // Submit feedback form
      await axios.post('/api/submit-feedback/submit', formData);
      alert('Feedback submitted successfully!');
  
      // Reset form data after successful submission
      setFormData({
        userId: '',
        careerFieldExplorationId: '',
        classroomTheoryApplicationId: '',
        decisionMakingSkillsId: '',
        workWorldKnowledgeId: '',
        communicationSkillsId: '',
        leadershipSkillsId: '',
        ethicalSensitivityId: '',
        confidenceInNewSituationsId: '',
        interpersonalSkillsId: '',
        responsibilityHandlingId: '',
        selfDiscoveryId: '',
        newInterestsId: '',
        careerGoalsClarificationId: '',
        futureEmploymentContactsId: '',
        equipmentUsageId: '',
        facultyCoordinatorMentorship: '',
        goalsAccomplishment: '',
        areasOfImprovement: '',
        significantAccomplishment: '',
        dislikes: '',
        overallExperienceRating: '',
        internshipImprovementSuggestions: '',
      });
  
      // Update feedback status (feedbackGiven) in the user profile
      if (formData.userId) {
        await axios.put(`/api/a/${formData.userId}/feedback`, {
          feedbackGiven: true,
        });
        console.log('Feedback status updated successfully');
      }
  
    } catch (error) {
      console.error('Error submitting feedback or updating feedback status:', error);
      alert('There was an error submitting your feedback. Please try again.');
    }
  };
  

  return (
    <div className={styles.feedbackContainer}>
      <h1 className={styles.feedbackTitle}>Student Feedback</h1>
      {/* Feedback Form */}
      <form onSubmit={handleSubmit} className={styles.feedbackForm}>
        <h2 className={styles.feedbackSectionHeader}>Experience Evaluation</h2>
        {[
          'careerFieldExploration',
          'classroomTheoryApplication',
          'decisionMakingSkills',
          'workWorldKnowledge',
          'communicationSkills',
          'leadershipSkills',
          'ethicalSensitivity',
          'confidenceInNewSituations',
          'interpersonalSkills',
          'responsibilityHandling',
          'selfDiscovery',
          'newInterests',
          'careerGoalsClarification',
          'futureEmploymentContacts',
          'equipmentUsage',
        ].map((statement, index) => (
          <div key={index}>
            <label className={styles.feedbackLabel}>
              {statement.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}:
            </label>
            <select
              name={`${statement}Id`}
              value={formData[`${statement}Id` as keyof StudentFeedbackData] as string}
              onChange={handleChange}
              className={styles.feedbackSelect}
              required
            >
              <option value="">Select...</option>
              {likertOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        ))}


        <h2 className={styles.feedbackSectionHeader}>Open-ended Questions</h2>
        <div>
          <label className={styles.feedbackLabel}>
            In the Institute internship program, faculty members are expected to be mentors for students. Do you feel that your faculty coordinator served such a function? Why or why not?
          </label>
          <textarea
            name="facultyCoordinatorMentorship"
            value={formData.facultyCoordinatorMentorship}
            onChange={handleChange}
            className={styles.feedbackTextarea}
            required
          />
        </div>
        <div>
          <label className={styles.feedbackLabel}>
            How well were you able to accomplish the initial goals, tasks, and new skills that were set down in your learning contract? In what ways were you able to take a new direction or expand beyond your contract? Why were some goals not accomplished adequately?
          </label>
          <textarea
            name="goalsAccomplishment"
            value={formData.goalsAccomplishment}
            onChange={handleChange}
            className={styles.feedbackTextarea}
            required
          />
        </div>
        <div>
          <label className={styles.feedbackLabel}>
            In what areas did you most develop and improve?
          </label>
          <textarea
            name="areasOfImprovement"
            value={formData.areasOfImprovement}
            onChange={handleChange}
            className={styles.feedbackTextarea}
            required
          />
        </div>
        <div>
          <label className={styles.feedbackLabel}>
            What has been the most significant accomplishment or satisfying moment of your internship?
          </label>
          <textarea
            name="significantAccomplishment"
            value={formData.significantAccomplishment}
            onChange={handleChange}
            className={styles.feedbackTextarea}
            required
          />
        </div>
        <div>
          <label className={styles.feedbackLabel}>
            What did you dislike about the internship?
          </label>
          <textarea
            name="dislikes"
            value={formData.dislikes}
            onChange={handleChange}
            className={styles.feedbackTextarea}
            required
          />
        </div>
        <div>
          <label className={styles.feedbackLabel}>
            Considering your overall experience, how would you rate this internship?
          </label>
          <select
            name="overallExperienceRating"
            value={formData.overallExperienceRating}
            onChange={handleChange}
            className={styles.feedbackSelect}
            required
          >
            <option value="">Select...</option>
            <option value="Satisfactory">Satisfactory</option>
            <option value="Good">Good</option>
            <option value="Excellent">Excellent</option>
          </select>
        </div>
        <div>
          <label className={styles.feedbackLabel}>
            Give suggestions as to how your internship experience could have been improved. (Could you have handled added responsibility? Would you have liked more discussions with your professor concerning your internship? Was closer supervision needed? Was more of an orientation required?)
          </label>
          <textarea
            name="internshipImprovementSuggestions"
            value={formData.internshipImprovementSuggestions}
            onChange={handleChange}
            className={styles.feedbackTextarea}
            required
          />
        </div>
        <button type="submit" className={styles.feedbackSubmitButton}>Submit Feedback</button>
      </form>

      {/* Display feedback entries */}
      <ul className={styles.feedbackList}>
        {feedback.map((item) => (
          <li key={item.id} className={styles.feedbackItem}>
            <strong>User ID:</strong> {item.userId} <br />
            <strong>Career Field Exploration:</strong> {item.careerFieldExplorationId} <br />
            <strong>Classroom Theory Application:</strong> {item.classroomTheoryApplicationId} <br />
            <strong>Decision Making Skills:</strong> {item.decisionMakingSkillsId} <br />
            <strong>Work World Knowledge:</strong> {item.workWorldKnowledgeId} <br />
            <strong>Communication Skills:</strong> {item.communicationSkillsId} <br />
            <strong>Leadership Skills:</strong> {item.leadershipSkillsId} <br />
            <strong>Ethical Sensitivity:</strong> {item.ethicalSensitivityId} <br />
            <strong>Confidence in New Situations:</strong> {item.confidenceInNewSituationsId} <br />
            <strong>Interpersonal Skills:</strong> {item.interpersonalSkillsId} <br />
            <strong>Responsibility Handling:</strong> {item.responsibilityHandlingId} <br />
            <strong>Self Discovery:</strong> {item.selfDiscoveryId} <br />
            <strong>New Interests:</strong> {item.newInterestsId} <br />
            <strong>Career Goals Clarification:</strong> {item.careerGoalsClarificationId} <br />
            <strong>Future Employment Contacts:</strong> {item.futureEmploymentContactsId} <br />
            <strong>Equipment Usage:</strong> {item.equipmentUsageId} <br />
            <strong>Faculty Coordinator Mentorship:</strong> {item.facultyCoordinatorMentorship} <br />
            <strong>Goals Accomplishment:</strong> {item.goalsAccomplishment} <br />
            <strong>Areas of Improvement:</strong> {item.areasOfImprovement} <br />
            <strong>Significant Accomplishment:</strong> {item.significantAccomplishment} <br />
            <strong>Dislikes:</strong> {item.dislikes} <br />
            <strong>Overall Experience Rating:</strong> {item.overallExperienceRating} <br />
            <strong>Internship Improvement Suggestions:</strong> {item.internshipImprovementSuggestions} <br />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StudentFeedback;
