import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
import Chatbot from '../components/Chatbot';
import { useNavigate } from 'react-router-dom';

import logoutImage from '../img/log-out_10117545.png';
import info from '../img/information-point_11606639 (1).png';
import { AiOutlineClose } from 'react-icons/ai';



interface User {
  id: number;
  IIMSTC_ID: string;
  name: string;
  dob: Date;
  address: string;
  collegeId: number;
  university: string;
  usn: string;
  email: string;
  gender: string;
  semester: string;
  phoneNumber: string;
  passportPhoto: string;
  aadharNo: string;
  aadharProof: string;
  degreeId: number;
  degreeStatusId: number | null;
  branchId: number;
  InternshipApproved: boolean | null;
  interviewScheduled: boolean;
  interviewReject: boolean;
}

interface PreScreening {
  isApproved: boolean;
  isApp: boolean;
  isDeclined: boolean;
  id: number;
  tenthBoard: string;
  tenthYear: string;
  tenthPercentage: string;
  tenthDocumentURL: string ;
  
  twelfthBoard: string;
  twelfthYear: string;
  twelfthPercentage: string;
  twelfthDocumentURL: string;
  
  degreeUniversity: string;
  degreeLastSemResult: string;
  degreeResultFileURL: string;


  videoURL: string;
  coverletterURL: string;
  resumeURL: string;
 
 
  user: User;
}

const InternshipDashboard: React.FC = () => {
  const [preScreenings, setPreScreenings] = useState<PreScreening[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // Move useState inside the component
  const [postScreenings, setPostScreenings] = useState<PreScreening[]>([]);
  const [interviewStatus, setInterviewStatus] = useState<User[]>([]);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const [activeSection, setActiveSection] = useState<'preScreening' | 'postScreening'| 'normal' | 'interviewStatus' | 'account'>('account');
  const [expandedUser, setExpandedUser] = useState<number | null>(null);
  const navigate = useNavigate();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const handleChatbotToggle = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };
  const [isHelpOpen, setIsHelpOpen] = useState(false);
const [collegeEntries, setCollegeEntries] = useState<any[]>([]);

  const handleLogout = () => {
    navigate('/reg');
  };

  useEffect(() => {
    const fetchPreScreenings = async () => {
      try {
        const response = await axios.get('/api/prescreening/all');
        const data = response.data;

        const preScreeningFiltered = data.filter((item: PreScreening) => {
          return item.user.InternshipApproved === null || item.user.InternshipApproved === undefined;
        });


        setPreScreenings(preScreeningFiltered);

      } catch (error) {
        console.error('Error fetching pre-screening data:', error);
      }
    };
  
    const fetchPostScreenings = async () => {
      try {
        const response = await axios.get('/api/approved-users');
        setPostScreenings(response.data);
      } catch (error) {
        console.error('Error fetching post-screening data:', error);
      }
    };

    const fetchInterviewStatus = async () => {
      try {
        const response = await axios.get('/api/scheduled-interviews');
        setInterviewStatus(response.data);
      } catch (error) {
        console.error('Error fetching interview status data:', error);
      }
    };
const fetchCollegeEntries = async () => {
  try {
    const response = await axios.get('/api/normal/normals'); // Use your actual API endpoint
    setCollegeEntries(response.data.data); // Adjust based on your API response structure
  } catch (error) {
    console.error('Error fetching college entries:', error);
  }
};

    fetchPreScreenings();
    fetchPostScreenings();
 fetchCollegeEntries();
    fetchInterviewStatus();
  }, []);

  const handleApprove = async (userId: number) => {
    try {
      await axios.patch(`/api/users/${userId}`, { InternshipApproved: true });
      await axios.post('/api/postscreening', { userId });

      setPreScreenings((prev) => prev.filter((screening) => screening.user.id !== userId));
      setPostScreenings((prev) => [...prev, ...preScreenings.filter((screening) => screening.user.id === userId)]);

      setExpandedUser(null);
      alert('Approval email sent successfully');
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleDecline = async (userId: number) => {
    try {
      await axios.patch(`/api/users/${userId}`, { InternshipApproved: false });

      setPreScreenings((prev) => prev.filter((screening) => screening.user.id !== userId));

      setExpandedUser(null);
      alert('User declined successfully');
    } catch (error) {
      console.error('Error declining user:', error);
    }
  };

  const handleScheduleInterview = async (userId: number) => {
    try {
      await axios.patch(`/api/schedule-interview/${userId}`);

      const updatedPostScreenings = postScreenings.filter((screening) => screening.user.id !== userId);

      const userToSchedule = postScreenings.find((screening) => screening.user.id === userId)?.user;

      if (userToSchedule) {
        setPostScreenings(updatedPostScreenings);
        setInterviewStatus((prev) => [...prev, userToSchedule]);
      }

      const eventTitle = `Interview with ${userToSchedule?.name}`;
      const calendarUrl = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(eventTitle)}`;

      window.open(calendarUrl, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.error('Error scheduling interview:', error);
    }
  };

  const handleRejectInterview = async (userId: number) => {
    try {
      await axios.patch(`/api/reject-interview/${userId}`);

      setInterviewStatus((prev) => prev.filter((user) => user.id !== userId));
      alert('Interview rejected successfully');
    } catch (error) {
      console.error('Error rejecting interview:', error);
    }
  };


  const toggleUserDetails = (userId: number) => {
    setExpandedUser((prev) => (prev === userId ? null : userId));
  };





  return (
    <div className="relative flex">
      <div className="fixed top-0 left-0 w-full h-14 bg-custom-tea z-10 flex items-center justify-between px-4">
        <img src="https://iimstc.com/wp-content/uploads/2021/10/log.png" alt="Logo" className="h-12" />
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setIsHelpOpen(true)}
            aria-label="Help"
            title="Help"
          >
            <img src={info} alt="Settings" style={{ width: '30px', height: '30px' }} />


          </button>

          <button
            onClick={handleLogout}

            aria-label="Logout"
            title="Logout"
          >
            <img src={logoutImage} alt="Log Out" style={{ width: 30, height: 30 }} />
          </button>
        </div>
      </div>

      <div className="bg-custom-tea text-white w-20 flex flex-col p-4 fixed h-full z-10 top-14 left-0">
        <button
          onClick={() => setIsSidebarMinimized(!isSidebarMinimized)}
          className="text-white mb-4"
          aria-label="Toggle sidebar"
        >
          <FaBars size={24} />
        </button>
      </div>

      <div
        className={`bg-custom-teal text-white transition-all duration-300 flex flex-col p-4 fixed h-full z-10 top-14 left-20 ${isSidebarMinimized ? 'w-0' : 'w-80'}`}
      >
        
        <button
          onClick={() => setActiveSection('account')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'account' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span>Account</span>
        </button>


        <button
          onClick={() => setActiveSection('preScreening')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'preScreening' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span>Pre-Screening</span>
        </button>
        <button
          onClick={() => setActiveSection('postScreening')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'postScreening' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span>Post-Screening</span>
        </button>
        <button
          onClick={() => setActiveSection('interviewStatus')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'interviewStatus' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span>Interview Status</span>
        </button>
         <button
          onClick={() => setActiveSection('normal')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'normal' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span>Pre Registrations</span>
        </button>
      </div>

      <div
        className={`bg-white transition-all duration-300 p-4 fixed h-full z-10 top-14 ${isSidebarMinimized ? 'left-20 w-[calc(100%-80px)]' : 'left-80 w-[calc(100%-320px)]'
          }`}
      >
        <div className="w-full h-[calc(100%-4rem)] overflow-y-auto p-4">
        {activeSection === 'account' && (
  <div className="mt-6 flex justify-center">
    <div className='w-[90%]'>
      <h2 className="text-2xl font-bold mb-4 text-center">Account Details</h2>
      
      {/* Wrapper div for the table with 75% width */}
      <div className="w-3/4 mx-auto"> 
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 px-4 py-2">Field</th>
              <th className="border border-gray-300 px-4 py-2">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Name</td>
              <td className="border border-gray-300 px-4 py-2">Rajesh Kumar</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Email</td>
              <td className="border border-gray-300 px-4 py-2">rajesh.kumar@example.com</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Department</td>
              <td className="border border-gray-300 px-4 py-2">Computer Science</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Division</td>
              <td className="border border-gray-300 px-4 py-2">A</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Designation</td>
              <td className="border border-gray-300 px-4 py-2">HOD</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Address</td>
              <td className="border border-gray-300 px-4 py-2">123, MG Road, Bengaluru, Karnataka</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Phone Number</td>
              <td className="border border-gray-300 px-4 py-2">+91 9876543210</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
)}


          {activeSection === 'preScreening' && (
            <>
              <h1 className="text-2xl font-bold mb-4">Pre-Screening</h1>
              {preScreenings.length === 0 ? (
                <p className="text-black">No pre-screening data available.</p>
              ) : (
                preScreenings.map((screening) => (
                  <div key={screening.id} className="border-b py-4">
                    <div>
                      <h2 className="text-lg font-semibold">{screening.user.name}</h2>
                      <button
                        onClick={() => toggleUserDetails(screening.user.id)}
                        className="text-blue-500"
                      >
                        {expandedUser === screening.user.id ? 'Hide Details' : 'Show Details'}
                      </button>
                    </div>
                    {expandedUser === screening.user.id && (
                      <div className="mt-2 text-black ">
                        <div className="w-7/10">
                          <table className="min-w-full table-auto border-collapse border border-gray-500 w-full rounded-lg shadow-lg">
                            <thead>
                              <tr className="bg-gray-100">
                                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Field</th>
                                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-blue-200">
                                <td className="border border-gray-500 px-4 py-2 font-bold">USN</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.user.usn}</td>
                              </tr>
                              <tr className="bg-white">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Email</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.user.email}</td>
                              </tr>
                              <tr className="bg-blue-200">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Phone</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.user.phoneNumber}</td>
                              </tr>
                              <tr className="bg-white">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Address</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.user.address}</td>
                              </tr>
                              <tr className="bg-blue-200">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Board</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.tenthBoard}</td>
                              </tr>
                              <tr className="bg-white">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Year</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.tenthYear}</td>
                              </tr>
                              <tr className="bg-blue-200">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Percentage</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.tenthPercentage}</td>
                              </tr>
                              <tr className="bg-white">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Board</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.twelfthBoard}</td>
                              </tr>
                              <tr className="bg-blue-200">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Year</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.twelfthYear}</td>
                              </tr>
                              <tr className="bg-white">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Percentage</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.twelfthPercentage}</td>
                              </tr>
                              <tr className="bg-blue-200">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Degree University</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.degreeUniversity}</td>
                              </tr>
                              <tr className="bg-white">
                                <td className="border border-gray-500 px-4 py-2 font-bold">Degree Last Sem Result</td>
                                <td className="border border-gray-500 px-4 py-2">{screening.degreeLastSemResult}</td>
                              </tr>
                              {/* Adding Certificate row */}
                              <tr className="bg-blue-200">
  <td className="border border-gray-500 px-4 py-2 font-bold">Degree University</td>
  <td className="border border-gray-500 px-4 py-2">{screening.degreeUniversity}</td>
</tr>
 <tr className="bg-white">
  <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Marks Card</td>
  <td className="border border-gray-500 px-4 py-2">
    <a href={screening.tenthDocumentURL} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">View Document</a>
  </td>
</tr>   
 <tr className="bg-blue-200">
  <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Marks Card: </td>
  <td className="border border-gray-500 px-4 py-2">
    <a href={screening.twelfthDocumentURL} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">View Document</a>
  </td>
</tr>                             
<tr className="bg-white">
  <td className="border border-gray-500 px-4 py-2 font-bold">Degree Result</td>
  <td className="border border-gray-500 px-4 py-2">
    <a href={screening.degreeResultFileURL} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">View Document</a>
  </td>
</tr>
<tr className="bg-blue-200">
  <td className="border border-gray-500 px-4 py-2 font-bold">Resume</td>
  <td className="border border-gray-500 px-4 py-2">
    <a href={screening.resumeURL} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">View Document</a>
  </td>
</tr>
<tr className="bg-white">
  <td className="border border-gray-500 px-4 py-2 font-bold">Video</td>
  <td className="border border-gray-500 px-4 py-2">
    <a href={screening.videoURL} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">Watch Video</a>
  </td>
</tr>
                            </tbody>
                          </table>
                        </div>







                        {/* Display images beside each other */}




                        <div className="flex space-x-4 mt-2">
                          <button
                            onClick={() => handleApprove(screening.user.id)}
                            className="bg-green-500 text-white px-4 py-2 rounded-md"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => handleDecline(screening.user.id)}
                            className="bg-red-500 text-white px-4 py-2 rounded-md ml-4"
                          >
                            Decline
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              )}
            </>
          )}

{activeSection === 'postScreening' && (
  <>
    <h1 className="text-2xl font-bold mb-4">Post-Screening</h1>
    {postScreenings.length === 0 ? (
      <p>No post-screening data available.</p>
    ) : (
      <div className="mt-2 text-black flex ">
        <div className="w-[90%]"> {/* Changed width to 75% */}
          <table className="min-w-full table-auto border-collapse border border-gray-500 w-full rounded-lg shadow-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Name</th>
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">USN</th>
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">College Name</th>
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Branch</th>
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Email</th>
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Action</th>
              </tr>
            </thead>
            <tbody>
              {postScreenings.map((screening) => (
                <tr key={screening.id} className="bg-white">
                  <td className="border border-gray-500 px-4 py-2">{screening.user.name}</td>
                  <td className="border border-gray-500 px-4 py-2">{screening.user.usn}</td>
                  <td className="border border-gray-500 px-4 py-2">Add Collega Name</td>
                  <td className="border border-gray-500 px-4 py-2">Add Branch here</td>
                  <td className="border border-gray-500 px-4 py-2">{screening.user.email}</td>
                  <td className="border border-gray-500 px-4 py-2">
                    <button
                      onClick={() => handleScheduleInterview(screening.user.id)}
                      className="bg-blue-300 text-white px-4 py-2 rounded hover:bg-blue-400"
                    >
                      Schedule Interview
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )}
  </>
)}



{activeSection === 'interviewStatus' && (
  <>
    <h1 className="text-2xl font-bold mb-4">Interview Status</h1>
    {interviewStatus.length === 0 ? (
      <p>No interview status data available.</p>
    ) : (
      <div className="mt-2 text-black flex ">
        <div className="w-3/4"> {/* Set table width to 75% */}
          <table className="min-w-full table-auto border-collapse border border-gray-500 w-full rounded-lg shadow-lg">
            <thead>
              <tr className="bg-gray-100">
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Name</th>
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Accept Interview</th>
                <th className="border border-gray-500 px-4 py-2 text-left font-bold">Reject Interview</th>
              </tr>
            </thead>
            <tbody>
              {interviewStatus.map((user) => (
                <tr key={user.id} className="bg-white">
                  {/* Name Column */}
                  <td className="border border-gray-500 px-4 py-2">{user.name}</td>
                  
                  {/* Accept Interview Button Column */}
                  <td className="border border-gray-500 px-4 py-2">
                    <button
                      className="bg-blue-300 text-white px-4 py-2 rounded hover:bg-blue-400"
                    >
                      Accept Interview
                    </button>
                  </td>
                  
                  {/* Reject Interview Button Column */}
                  <td className="border border-gray-500 px-4 py-2">
                    <button
                      onClick={() => handleRejectInterview(user.id)}
                      className="bg-red-500 text-black px-4 py-2 rounded hover:bg-red-600"
                    >
                      Reject Interview
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )}
  </>
)}
{activeSection === 'normal' && (
  <>
    <h2>College Entries</h2>
    <ul>
      {collegeEntries.map((collegeEntry, index) => (
        <li key={index}>
          <h3>{collegeEntry.college.name}</h3>
          <ul>
            {collegeEntry.entries.map((entry: any, entryIndex: React.Key) => (
              <li key={entryIndex}>
                <table className="min-w-full table-auto border-collapse border border-gray-500 w-full rounded-lg shadow-lg">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="border border-gray-500 px-4 py-2 text-left font-bold">Field</th>
                      <th className="border border-gray-500 px-4 py-2 text-left font-bold">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Email</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.email}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Name</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.name}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Date of Birth</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.dob}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Address</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.address}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">College ID</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.collegeId}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Degree ID</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.degreeId}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Branch ID</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.branchId}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Degree Status ID</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.degreeStatusId}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">University</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.university}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">USN</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.usn}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Aadhar Number</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.aadharNo}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Gender</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.gender}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Semester</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.semester}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Phone Number</td>
                                            <td className="border border-gray-500 px-4 py-2">{entry.phoneNumber}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Passcode</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.passcode}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Board</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.tenthBoard}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Year</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.tenthYear}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Percentage</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.tenthPercentage}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Tenth Document URL</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.tenthDocumentURL}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Board</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.twelfthBoard}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Year</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.twelfthYear}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Percentage</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.twelfthPercentage}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Twelfth Document URL</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.twelfthDocumentURL}</td>
                    </tr>
                    <tr className="bg-white">
                                           <td className="border border-gray-500 px-4 py-2 font-bold">Degree University</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.degreeUniversity}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Degree Last Sem Result</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.degreeLastSemResult}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Degree Result File URL</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.degreeResultFileURL}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Video URL</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.videoURL}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Resume URL</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.resumeURL}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Cover Letter URL</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.coverletterURL}</td>
                    </tr>
                    <tr className="bg-white">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Passport Photo</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.passportPhoto}</td>
                    </tr>
                    <tr className="bg-blue-200">
                      <td className="border border-gray-500 px-4 py-2 font-bold">Aadhar Proof</td>
                      <td className="border border-gray-500 px-4 py-2">{entry.aadharProof}</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  </>
)}


          {isHelpOpen && (
            <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex items-center justify-center">
              <div className="bg-white p-6 rounded-md shadow-lg">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-xl font-semibold">Help</h2>
                  <button
                    onClick={() => setIsHelpOpen(false)}
                    className="text-gray-500"
                    aria-label="Close Help"
                  >
                    <AiOutlineClose size={24} />
                  </button>
                </div>
                <p>If you have any questions or need assistance, please contact support.</p>

              </div>
            </div>
          )}

          <button onClick={handleChatbotToggle} className="fixed bottom-4 right-4 bg-teal-600 text-white p-4 rounded-full shadow-lg">
            {isChatbotOpen ? "Close Chatbot" : "Help?"}
          </button>

          {isChatbotOpen && <Chatbot onClose={handleChatbotToggle} />}
        </div>

      </div>
    </div>
  );
};

export default InternshipDashboard;
