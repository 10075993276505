import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Chatbot from '../components/Chatbot';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import aImage from '../img/payment.jpeg';
import logoutImage from '../img/log-out_10117545.png';
import info from '../img/information-point_11606639 (1).png';
import { AiOutlineClose } from 'react-icons/ai';



interface Payment {
  id: number;
  name: string;
  paymentMode: string;
  utrNo: string;
  amount: string;
  BankName: string;
  userId: number;
  createdAt: Date;
  status: string;
  referenceFile: Uint8Array; // File as a buffer
  fileName: string; // File name with extension
}

interface InterviewStatus {
  id: number;
  userId: number;
  status: boolean;
  user: {
    id: number;
    name: string;
  };
}

const FinanceDashboard: React.FC = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [rejectedInterviews, setRejectedInterviews] = useState<InterviewStatus[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const [activeSection, setActiveSection] = useState<'none' | 'newPayment' | 'refund' | 'account'>('none');
  const [expandedRowId, setExpandedRowId] = useState<number | null>(null);
  const [selectedUserPayment, setSelectedUserPayment] = useState<Payment | null>(null);
  const [fileArray, setFileArray] = useState<Uint8Array | undefined>(undefined);
  const navigate = useNavigate(); // Initialize useNavigate
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const handleChatbotToggle = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };
  const [isHelpOpen, setIsHelpOpen] = useState(false);


  const handleLogout = () => {
    // Perform any necessary cleanup, such as clearing tokens
    navigate('/reg'); // Navigate to /reg
  };

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get('/api/payments/all');
        const unverifiedPayments = response.data.filter((payment: { status: string; }) => payment.status !== 'verified');
        setPayments(unverifiedPayments);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching payments:', error);
        setError('Failed to load payments.');
        setLoading(false);
      }
    };

    const fetchRejectedInterviews = async () => {
      try {
        const response = await axios.get('/api/rejected-interviews');
        if (response.data && response.data.rejectedInterviews && Array.isArray(response.data.rejectedInterviews)) {
          setRejectedInterviews(response.data.rejectedInterviews);
        } else {
          
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching rejected interviews:', error);
        
        setLoading(false);
      }
    };

    if (activeSection === 'newPayment') {
      fetchPayments();
    } else if (activeSection === 'refund') {
      fetchRejectedInterviews();
    }
  }, [activeSection]);

  const toggleExpandRow = (id: number) => {
    setExpandedRowId(expandedRowId === id ? null : id);
  };
  

  const handleAction = async (paymentId: number, userId: number, action: 'approve' | 'decline') => {
    try {
      const response = await axios.post('/api/verify-payment/verify', {
        paymentId,
        userId,
        action
      });

      // If successful, update the payment status locally
      if (response.status === 200) {
        setPayments(payments.filter(payment => payment.id !== paymentId));
      }
    } catch (error) {
      console.error(`Error ${action} payment:`, error);
      setError(`Failed to ${action} payment.`);
    }
  };
  // Function to render image with additional logging
  
  const fetchPaymentDetails = async (userId: number) => {
    try {
      const response = await axios.get(`/api/payments/${userId}`);
      setSelectedUserPayment(response.data);
    } catch (error) {
      console.error('Error fetching payment details:', error);
      setError('Failed to load payment details.');
    }
  };

  return (
    <div className="relative flex">
      <div className="fixed top-0 left-0 w-full h-14 bg-custom-tea z-10 flex items-center justify-between px-4">
        <img src="https://iimstc.com/wp-content/uploads/2021/10/log.png" alt="Logo" className="h-12" />
        <div className="flex items-center space-x-4">
        <button
            onClick={() => setIsHelpOpen(true)}
            aria-label="Help"
             title="Help"
          >
           <img src={info} alt="Settings" style={{ width: '30px', height: '30px' }} />


          </button>
         
          <button
            onClick={handleLogout}
            
            aria-label="Logout"
            title="Logout"
          >
            <img src={logoutImage} alt="Log Out" style={{ width: 30, height: 30 }} />
          </button>
        </div>
      </div>

      <div className="bg-custom-tea text-white w-20 flex flex-col p-4 fixed h-full z-10 top-14 left-0">
        <button
          onClick={() => setIsSidebarMinimized(!isSidebarMinimized)}
          className="text-white mb-4"
          aria-label="Toggle sidebar"
        >
          <FaBars size={24} />
        </button>

        

        
      </div>

      <div
        className={`bg-custom-teal text-white transition-all duration-300 flex flex-col p-4 fixed h-full z-10 top-14 ${isSidebarMinimized ? 'left-20 w-0' : 'left-20 w-60'}`}
      >
        <button
          onClick={() => setActiveSection('account')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'account' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span>Account</span>
        </button>

        <button
          onClick={() => setActiveSection('newPayment')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'newPayment' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span >New Payment</span>
        </button>
        <button
          onClick={() => setActiveSection('refund')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'refund' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span >Refund</span>
        </button>
      </div>

      <div
        className={`bg-white transition-all duration-300 p-4 fixed h-full z-10 top-14 ${isSidebarMinimized ? 'left-20' : 'left-60'} w-[calc(100%-80px)]`}
      >
        <div className="h-[calc(100vh-56px)] overflow-y-auto"> {
        
        activeSection === 'account' && (
  <div className="mt-6 flex justify-center">
    <div>
      <h2 className="text-2xl font-bold mb-4 text-center">Account Details</h2>
      <table className="table-auto w-300 border-collapse border border-gray-300">
        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Field</th>
            <th className="border border-gray-300 px-4 py-2">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Name</td>
            <td className="border border-gray-300 px-4 py-2">Rajesh Kumar</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Email</td>
            <td className="border border-gray-300 px-4 py-2">rajesh.kumar@example.com</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Department</td>
            <td className="border border-gray-300 px-4 py-2">Computer Science</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Division</td>
            <td className="border border-gray-300 px-4 py-2">A</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Designation</td>
            <td className="border border-gray-300 px-4 py-2">HOD</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Address</td>
            <td className="border border-gray-300 px-4 py-2">123, MG Road, Bengaluru, Karnataka</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Phone Number</td>
            <td className="border border-gray-300 px-4 py-2">+91 9876543210</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)}
        {activeSection === 'newPayment' && (
  <div>
    <h2 className="text-xl font-bold text-black mt-4 text-center">New Payment</h2>
    {loading ? (
      <p>Loading...</p>
    ) : error ? (
      <p className="text-red-600">{error}</p>
    ) : (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-left">Name</th>
              <th className="py-2 px-4 border-b text-left">IIMSTC_ID</th>
              <th className="py-2 px-4 border-b text-left">Amount</th>
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <React.Fragment key={payment.id}>
                <tr onClick={() => toggleExpandRow(payment.id)}>
                  <td className="py-2 px-4 border-b cursor-pointer">{payment.name}</td>
                  <td className="py-2 px-4 border-b cursor-pointer">{payment.userId}</td>
                  <td className="py-2 px-4 border-b cursor-pointer">{payment.amount}</td>
                </tr>
                {expandedRowId === payment.id && (
                  <tr>
                    <td colSpan={3} className="py-2 px-4 border-b">
                      <div className="bg-gray-100 p-4 rounded-lg shadow-inner">
                        <p><strong>Payment Mode:</strong> {payment.paymentMode}</p>
                        <p><strong>UTR No:</strong> {payment.utrNo}</p>
                        <p><strong>Bank Name:</strong> {payment.BankName}</p>
                        <p><strong>Date:</strong> {new Date(payment.createdAt).toLocaleDateString()}</p>
                        {payment.referenceFile ? (
  <>
    <p><strong>Reference File:</strong></p>
    <img src={aImage} alt="Reference" style={{ width: '400px', height: '500px', objectFit: 'cover' }} />
    <div className="flex space-x-4 mt-4">
      <button
        onClick={() => handleAction(payment.id, payment.userId, 'approve')}
        className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg"
      >
        Approve
      </button>
      <button
        onClick={() => handleAction(payment.id, payment.userId, 'decline')}
        className="mt-4 px-4 py-2 bg-red-500 text-white rounded-lg"
      >
        Decline
      </button>
    </div>
  </>
) : (
  <p>No reference file available.</p>
)}


                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
)}








{activeSection === 'refund' && (
  <div>
    <h2 className="text-xl font-bold text-black mt-4 text-center">Rejected Interviews</h2>
    {loading ? (
      <p>Loading...</p>
    ) : error ? (
      <p className="text-red-600">{error}</p>
    ) : (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-left">User Name</th>
              <th className="py-2 px-4 border-b text-left">User ID</th>
            </tr>
          </thead>
          <tbody>
            {rejectedInterviews.map((interview) => (
              <tr key={interview.id} onClick={() => fetchPaymentDetails(interview.userId)}>
                <td className="py-2 px-4 border-b cursor-pointer">{interview.user.name}</td>
                <td className="py-2 px-4 border-b cursor-pointer">{interview.userId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
    {selectedUserPayment && (
      <div className="mt-4 p-4 bg-gray-100 rounded-lg">
        <h3 className="text-lg font-bold">Payment Details</h3>
        <p><strong>Amount:</strong> {selectedUserPayment.amount}</p>
        <p><strong>Payment Mode:</strong> {selectedUserPayment.paymentMode}</p>
        <p><strong>Bank Name:</strong> {selectedUserPayment.BankName}</p>
        <p><strong>Date:</strong> {new Date(selectedUserPayment.createdAt).toLocaleDateString()}</p>
        {selectedUserPayment.referenceFile ? (
          <>
            <p><strong>Reference File:</strong></p>
    <img src={aImage} alt="Reference" style={{ width: '400px', height: '500px', objectFit: 'cover' }} />
          </>
        ) : (
          <p>No reference file available.</p>
        )}
        <button
          onClick={() => alert('Refund has been initiated')}
          className="mt-4 px-4 py-2 bg-green-500 text-white rounded-lg"
        >
          Refund
        </button>
      </div>
    )}
  </div>
)}
</div>
{isHelpOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Help</h2>
              <button
                onClick={() => setIsHelpOpen(false)}
                className="text-gray-500"
                aria-label="Close Help"
              >
                <AiOutlineClose size={24} />
              </button>
            </div>
            <p>If you have any questions or need assistance, please contact support.</p>
            
          </div>
        </div>
      )}


        <button onClick={handleChatbotToggle} className="fixed bottom-4 right-4 bg-teal-600 text-white p-4 rounded-full shadow-lg">
        {isChatbotOpen ? "Close Chatbot" : "Help?"}
      </button>

      {isChatbotOpen && <Chatbot onClose={handleChatbotToggle} />}
      </div>
    </div>
  );
};

export default FinanceDashboard;
