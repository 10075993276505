import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
import Chatbot from '../components/Chatbot';
import { useNavigate } from 'react-router-dom';
import logoutImage from '../img/log-out_10117545.png';
import info from '../img/information-point_11606639 (1).png';
import { AiOutlineClose } from 'react-icons/ai';

interface Attendance {
  date: string;
  isPresent: boolean;
  userId: number;
  userName: string;
}

interface DailyLog {
  id: number;
  day: string;
  date: string;
  arrivalTime: string;
  departureTime: string;
  remarks?: string;
  department: string;
  finishedProduct: string;
  hodName: string;
  hodEmail: string;
  mainPoints: string;
}

interface User {
  id: number;
  name: string;
  email: string;
}

const IndustryDashboard: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [dailyLogs, setDailyLogs] = useState<DailyLog[]>([]);
  const [selectedLog, setSelectedLog] = useState<DailyLog | null>(null);
  const [attendanceData, setAttendanceData] = useState<{ [date: string]: Attendance[] }>({});
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const handleChatbotToggle = () => {
    setIsChatbotOpen(!isChatbotOpen);
  };
  const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleLogout = () => {
    // Perform any necessary cleanup, such as clearing tokens
    navigate('/reg'); // Navigate to /reg
  };
  const [activeSection, setActiveSection] = useState<'none' | 'dashboard' | 'attendance' | 'account'>('dashboard');
  const [attendanceDate, setAttendanceDate] = useState<string>('');

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get('/api/users/all');
        const data = response.data;
        if (data.success && Array.isArray(data.users)) {
          setUsers(data.users);
        } else {
          console.error('API response does not contain a valid array of users:', data);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Failed to fetch users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      const fetchDailyLogs = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`/api/daily-logs/${selectedUser.id}`);
          if (response.data.success) {
            setDailyLogs(response.data.dailyLogs);
          } else {
            console.error('API response does not contain valid daily logs:', response.data);
          }
        } catch (error) {
          console.error('Error fetching daily logs:', error);
          setError('Failed to fetch daily logs');
        } finally {
          setLoading(false);
        }
      };

      fetchDailyLogs();
    }
  }, [selectedUser]);

  const handleSaveAttendance = async () => {
    if (!attendanceDate) {
      alert('Please enter the attendance date');
      return;
    }

    const attendanceRecords = users.map((user) => {
      const inputElement = document.querySelector(`input[name="attendance-${user.id}"]:checked`) as HTMLInputElement;
      const isPresent = inputElement?.value === 'present';
      return { id: user.id, isPresent };
    });
  
    try {
      const response = await axios.post('/api/attendance/save-all', {
        attendanceDate,
        attendanceRecords,
      });
  
      if (response.data.success) {
        alert('Attendance saved successfully for all users!');
      } else {
        alert('Failed to save attendance for all users');
      }
    } catch (error) {
      console.error('Error saving attendance for all users:', error);
      setError('Failed to save attendance for all users');
    }
  };

  const handleViewAttendance = async () => {
    try {
      const response = await axios.get('/api/attendance/list');
      if (response.data.success) {
        const attendanceList = response.data.attendanceList.reduce((acc: any, record: any) => {
          const date = new Date(record.date).toLocaleDateString();
          if (!acc[date]) acc[date] = [];
          acc[date].push({
            ...record,
            userName: record.user.name,
          });
          return acc;
        }, {});
        setAttendanceData(attendanceList);
      } else {
        alert('Failed to fetch attendance');
      }
    } catch (error) {
      console.error('Error fetching attendance:', error);
      setError('Failed to fetch attendance');
    }
  };

  const handleDateClick = (date: string) => {
    setSelectedDate(date === selectedDate ? null : date);
  };

  return (
    <div className="relative flex min-h-screen">
      <div className="fixed top-0 left-0 w-full h-14 bg-custom-tea z-10 flex items-center justify-between px-4">
        <img src="https://iimstc.com/wp-content/uploads/2021/10/log.png" alt="Logo" className="h-12" />
        <div className="flex items-center space-x-4">
        <button
            onClick={() => setIsHelpOpen(true)}
            aria-label="Help"
             title="Help"
          >
           <img src={info} alt="Settings" style={{ width: '30px', height: '30px' }} />


          </button>
         
          <button
            onClick={handleLogout}
            
            aria-label="Logout"
            title="Logout"
          >
            <img src={logoutImage} alt="Log Out" style={{ width: 30, height: 30 }} />
          </button>
        </div>
      </div>

      <div className="bg-custom-tea text-white w-20 flex flex-col p-4 fixed h-full z-10 top-14 left-0">
        <button
          onClick={() => setIsSidebarMinimized(!isSidebarMinimized)}
          className="text-white mb-4"
          aria-label="Toggle sidebar"
        >
          <FaBars size={24} />
        </button>
      </div>

      <div
  className={`bg-custom-teal text-white transition-all duration-300 flex flex-col p-4 fixed h-full z-10 top-14 ${isSidebarMinimized ? 'left-20 w-0 opacity-0 overflow-hidden' : 'left-20 w-60 opacity-100'}`}
>
{!isSidebarMinimized && (
    <>
    <button
          onClick={() => setActiveSection('account')}
          className={`mb-4 p-2 rounded-md flex items-center ${activeSection === 'account' ? 'bg-white text-custom-teal' : ''}`}
        >
          <span className="font-bold">Account</span>
        </button>
      <button
        onClick={() => setActiveSection('dashboard')}
        className="mb-4 p-2 rounded-md flex items-center"
      >
        <span className="mt-10 font-bold">Daily Logs</span>
      </button>
      <button
        onClick={() => setActiveSection('attendance')}
        className="mb-4 p-2 rounded-md flex items-center"
      >
        <span className="mt-10 font-bold">Attendance</span>
      </button>
    </>
  )}
</div>



      <div className={`bg-white transition-all duration-300 fixed top-14 ${isSidebarMinimized ? 'left-20' : 'left-60'} w-[calc(100%-80px)] h-[calc(100vh-56px)] overflow-y-auto`}>
      {activeSection === 'account' && (
  <div className="mt-6 flex justify-center">
   <div className="w-full">
      <h2 className="text-2xl font-bold mb-4 text-center">Account Details</h2>
     <table className="table-auto w-[70%] ml-[15%] border-collapse border border-gray-300">

        <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2">Field</th>
            <th className="border border-gray-300 px-4 py-2">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Name</td>
            <td className="border border-gray-300 px-4 py-2">Rajesh Kumar</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Email</td>
            <td className="border border-gray-300 px-4 py-2">rajesh.kumar@example.com</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Department</td>
            <td className="border border-gray-300 px-4 py-2">Computer Science</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Division</td>
            <td className="border border-gray-300 px-4 py-2">A</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Designation</td>
            <td className="border border-gray-300 px-4 py-2">HOD</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Address</td>
            <td className="border border-gray-300 px-4 py-2">123, MG Road, Bengaluru, Karnataka</td>
          </tr>
          <tr>
            <td className="border border-gray-300 px-4 py-2">Phone Number</td>
            <td className="border border-gray-300 px-4 py-2">+91 9876543210</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)}
        {activeSection === 'dashboard' && (
  <div className="flex flex-col min-h-screen bg-gray-100">
    <main className="flex-1 p-6">
  <div className="container mx-auto w-[80%] bg-white shadow-md rounded-lg p-6">
    <h2 className="text-xl font-semibold mb-4">Day Logs</h2>

    {loading && <p>Loading...</p>}
    {error && <p className="text-red-600">{error}</p>}

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-blue-50 p-4 rounded-lg  w-[90%] shadow-sm">
        <h3 className="text-lg font-semibold text-blue-600">Users</h3>
        <ul>
          {users.length > 0 ? (
            users.map((user) => (
              <li
                key={user.id}
                className={`cursor-pointer mb-2 p-2 border text-black rounded-lg shadow-sm ${selectedUser?.id === user.id ? 'bg-blue-200' : 'hover:bg-blue-100'}`}
                onClick={() => {
                  setSelectedUser(user);
                  setDailyLogs([]); // Clear dailyLogs when user changes
                  setSelectedLog(null); // Clear selectedLog when user changes
                }}
              >
                <p className="text-black font-semibold">{user.name || 'No Name'}</p>
                <p className="text-black font-semibold">{user.email}</p>
              </li>
            ))
          ) : (
            <p className="text-black">No users found.</p>
          )}
        </ul>
      </div>

      {selectedUser && (
        <div className="bg-green-50 p-4 rounded-lg shadow-sm w-[90%] mx-auto">

          <h3 className="text-lg font-semibold text-green-600">Daily Logs for {selectedUser.name}</h3>
          {dailyLogs.length > 0 ? (
            <ul>
              {dailyLogs.map((log) => (
                <li
                  key={log.id}
                  className={`cursor-pointer mb-2 p-2 border rounded-lg shadow-sm ${selectedLog?.id === log.id ? 'bg-green-200' : 'hover:bg-green-100'}`}
                  onClick={() => setSelectedLog(log)}
                >
                  <h4 className="text-lg font-semibold">{log.day}</h4>
                  {selectedLog?.id === log.id && (
                    <>
                      <p className="text-gray-700 mt-1">Date: {log.date}</p>
                      <div className="mt-4">
                        <h4 className="text-lg font-semibold">Detailed Log</h4>
                        <p className="text-black">Day: {selectedLog.day}</p>
                        <p className="text-black">Date: {selectedLog.date}</p>
                        <p className="text-black">Arrival Time: {selectedLog.arrivalTime}</p>
                        <p className="text-black">Departure Time: {selectedLog.departureTime}</p>
                        <p className="text-black">Remarks: {selectedLog.remarks}</p>
                        <p className="text-black">Department: {selectedLog.department}</p>
                        <p className="text-black">Finished Product: {selectedLog.finishedProduct}</p>
                        <p className="text-black">HOD Name: {selectedLog.hodName}</p>
                        <p className="text-black">HOD Email: {selectedLog.hodEmail}</p>
                        <p className="text-black">Main Points: {selectedLog.mainPoints}</p>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-black">No daily logs available for this user.</p>
          )}
        </div>
      )}
    </div>
  </div>
</main>


  </div>
)}



{activeSection === 'attendance' && (
  <div className="container mx-auto p-6 max-w-4xl"> {/* Adjust max-width here */}
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-xl font-semibold mb-4">Attendance Management</h2>

      <div className="mb-4">
        <label htmlFor="attendanceDate" className="block text-gray-700 font-bold mb-2">
          Attendance Date:
        </label>
        <input
          type="date"
          id="attendanceDate"
          value={attendanceDate}
          onChange={(e) => setAttendanceDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <h3 className="text-lg text-black font-semibold mb-4">Mark Attendance</h3>
      {users.length > 0 ? (
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Present</th>
              <th className="px-4 py-2">Absent</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td className="border px-4 py-2">{user.name}</td>
                <td className="border px-4 py-2 text-center">
                  <input
                    type="radio"
                    name={`attendance-${user.id}`}
                    value="present"
                    className="form-radio"
                  />
                </td>
                <td className="border px-4 py-2 text-center">
                  <input
                    type="radio"
                    name={`attendance-${user.id}`}
                    value="absent"
                    className="form-radio"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-black">No users available to mark attendance.</p>
      )}

      <div className="mt-6">
        <button
          onClick={handleSaveAttendance}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Save Attendance
        </button>
      </div>

      <div className="mt-6">
        <h3 className="text-lg  text-black font-semibold mb-4">View Attendance</h3>
        <button
          onClick={handleViewAttendance}
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-4"
        >
          Load Attendance Records
        </button>

        {Object.keys(attendanceData).length > 0 && (
          <div>
            {Object.keys(attendanceData).map((date) => (
              <div key={date} className="mb-4">
                <div
                  className="cursor-pointer p-2 bg-gray-100 border border-gray-300 rounded-md"
                  onClick={() => handleDateClick(date)}
                >
                  <h4 className="text-lg font-semibold">{date}</h4>
                </div>

                {selectedDate === date && (
                  <div className="p-4 bg-gray-50 border border-gray-300 rounded-md mt-2">
                    <h5 className="font-bold">Attendance Details</h5>
                    <ul>
                      {attendanceData[date].map((record, index) => (
                        <li key={index} className="border-b border-gray-200 py-2">
                          <p className="text-black">
                            <span className="font-semibold">{record.userName}:</span>{' '}
                            <span className={record.isPresent ? 'text-green-500' : 'text-red-500'}>
                              {record.isPresent ? 'Present' : 'Absent'}
                            </span>
                          </p>

                        </li>
                      ))}
                    </ul>
                    <p className="mt-4 text-black">
                      <span className="font-semibold">Total Present:</span>{' '}
                      {attendanceData[date].filter((record) => record.isPresent).length}
                    </p>
                    <p className="text-black">
                      <span className="font-semibold">Total Absent:</span>{' '}
                      {attendanceData[date].filter((record) => !record.isPresent).length}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
)}
{isHelpOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">Help</h2>
              <button
                onClick={() => setIsHelpOpen(false)}
                className="text-gray-500"
                aria-label="Close Help"
              >
                <AiOutlineClose size={24} />
              </button>
            </div>
            <p className="text-black">If you have any questions or need assistance, please contact support.</p>
            
          </div>
        </div>
      )}


        <button onClick={handleChatbotToggle} className="fixed bottom-4 right-4 bg-teal-600 text-white p-4 rounded-full shadow-lg">
        {isChatbotOpen ? "Close Chatbot" : "Help?"}
      </button>

      {isChatbotOpen && <Chatbot onClose={handleChatbotToggle} />}
      </div>
    </div>
  );
};

export default IndustryDashboard;
