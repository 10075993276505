import axios from 'axios';





export const createInitialInternshipDetails = async (
  userId: number,
  internshipPreferences: string, // Added parameter
  learningObjectives: string, // Added parameters
  technicalSkills: string, // Added parameter
  internshipActivities: string, // Added parameter
  evidenceToFaculty: string, // Added parameter
  StartDate: Date, // Added parameter
  EndDate: Date // Added parameter
) => {
  try {
    const response = await axios.post(`/api/aicte-interns/initial`, {
      userId,
      internshipPreferences,
      learningObjectives,
      technicalSkills,
      internshipActivities,
      evidenceToFaculty,
      StartDate,
      EndDate,
      academicYear: '',
      schoolName: '',
      schoolCityState: '',
      campusAddress: '',
      campusPhoneNumber: '',
      campusFaxNumber: '',
      schoolEmail: '',
      hodName: '',
      hodEmail: '',
      facultyName: '',
      facultyEmail: '',
      facultyPhone: '',
      internshipCompany: '',
      internshipFacilitator: '',
      facilitatorEmail: '',
      IndustryFacilitatorName: '',
      IndustryFacilitatorEmail: '',
      projectName: '',
      coreArea: ''
    });

    return response.data;
  } catch (error) {
    console.error('Error creating initial Internship Details:', error);
    throw new Error('Internal Server Error');
  }
};
// Update AICTE Internship Faculty Information
export const updateFacultyInfo = async (
  userId: number,
  facultyInfo: {
    hodName: string;
    hodEmail: string;
    facultyName: string;
    facultyEmail: string;
    facultyPhone: string;
    campusAddress: string;
    campusPhoneNumber: string;
    campusFaxNumber: string;
    schoolCityState: string;
    academicYear: string;
    schoolEmail: string;
  }
) => {
  try {
    console.log('Updating Faculty Info:', {
      userId,
      ...facultyInfo,
    });

    const response = await axios.put(`/api/aicte-interns/faculty`, {
      userId,
      ...facultyInfo,
    });

    return response.data;
  } catch (error) {
    console.error('Error updating Faculty Information:', error);
    throw new Error('Internal Server Error');
  }
};



// Update AICTE Internship Industry Facilitator Information
export const updateIndustryFacilitatorInfo = async (
  userId: number,
  industryInfo: {
    internshipCompany: string;
    IndustryFacilitatorName: string;
    IndustryFacilitatorEmail: string;
    projectName: string;
    coreArea: string;
  }
) => {
  try {
    const response = await axios.put(`/api/aicte-interns/industry`, {
      userId,
      ...industryInfo
    });

    return response.data;
  } catch (error) {
    console.error('Error updating Industry Facilitator Information:', error);
    throw new Error('Internal Server Error');
  }
};

// Retrieve AICTE Intern by ID
export const getAicteInternById = async (id: number) => {
  try {
    const response = await axios.get(`/api/aicte-interns/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error retrieving AICTE Intern:', error);
    throw new Error('Internal Server Error');
  }
};
export const getInitialInternshipDetails = async (userId: string) => {
  try {
    const response = await axios.get(`/api/aicte-interns/initial/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting initial internship details:', error);
    throw error;
  }
};

// Service to get faculty information
export const getFacultyInfo = async (userId: string) => {
  try {
    const response = await axios.get(`/api/aicte-interns/faculty/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting faculty information:', error);
    throw error;
  }
};

// Service to get industry facilitator information
export const getIndustryFacilitatorInfo = async (userId: string) => {
  try {
    const response = await axios.get(`/api/aicte-interns/industry/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting industry facilitator information:', error);
    throw error;
  }
};
