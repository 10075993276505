import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface FeedbackFormProps {
  userId: number;
  onClose: () => void;
  isViewMode: boolean; // Updated this line
}

const feedbackCategories = [
  "Attendance and General Behavior",
  "Relation with Workers Behavior",
  "Initiative and Efforts in Learning",
  "Knowledge and Skills Improvement",
  "Contribution and Organization",
  "Overall Experience"
];

const FeedbackFormFaculty: React.FC<FeedbackFormProps> = ({ userId, onClose, isViewMode }) => {
  const [feedback, setFeedback] = useState<string[]>(new Array(feedbackCategories.length).fill(''));

  useEffect(() => {
    if (isViewMode) {
      // Fetch feedback data for view-only mode
      axios.get(`/api/faculty-feedback/${userId}`)
        .then(response => setFeedback([
          response.data.attendanceBehavior,
          response.data.relationWithWorkers,
          response.data.initiativeEfforts,
          response.data.knowledgeSkills,
          response.data.contribution,
          response.data.overallExperience,
        ]))
        .catch(error => console.error("Error loading feedback:", error));
    }
  }, [userId, isViewMode]);

  const handleFeedbackChange = (index: number, value: string) => {
    if (isViewMode) return; // Prevent editing if in view mode
    const updatedFeedback = [...feedback];
    updatedFeedback[index] = value;
    setFeedback(updatedFeedback);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!isViewMode) {
      try {
        const feedbackData = {
          userId,
          attendanceBehavior: feedback[0],
          relationWithWorkers: feedback[1],
          initiativeEfforts: feedback[2],
          knowledgeSkills: feedback[3],
          contribution: feedback[4],
          overallExperience: feedback[5],
        };
        const response = await axios.post('/api/faculty-feedback/save', feedbackData);
        if (response.status === 200) {
          console.log('Feedback submitted successfully');
          onClose();
        } else {
          console.error('Failed to submit feedback');
        }
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-center text-lg font-semibold mb-4">
          {isViewMode ? "View Feedback" : "Give Your Feedback"}
        </h2>
        <div className="max-h-[70vh] overflow-y-auto pr-6">
          {feedbackCategories.map((category, index) => (
            <div key={index}>
              <div className="flex justify-between">
                <h3 className="font-semibold">{index + 1}. {category}</h3>
              </div>
              <div className="flex justify-between mb-5">
                {["Excellent", "Very Good", "Satisfactory", "Not Satisfactory"].map(option => (
                  <label key={option} className="flex items-center">
                    <input
                      type="radio"
                      value={option}
                      checked={feedback[index] === option}
                      onChange={() => handleFeedbackChange(index, option)}
                      className="mr-2 ml-2"
                      disabled={isViewMode} // Disable if in view mode
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>
          ))}
          <div className="flex justify-center">
            {!isViewMode && (
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded-lg mr-4"
              >
                Submit
              </button>
            )}
            <button
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded-lg"
            >
              {isViewMode ? "Close" : "Cancel"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackFormFaculty;
