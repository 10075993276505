import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Content {
  text: string;
  imgSrc: string;
  details: string;
}

const Graduate: React.FC = () => {
  const [formData, setFormData] = useState({
    tenthBoard: '',
    tenthYear: '',
    tenthPercentage: '',
    twelfthBoard: '',
    twelfthYear: '',
    twelfthPercentage: '',
    degreeUniversity: '',
    degreeLastSemResult: '',
    userId: '',
    modelId: '', // This will store the selected model ID
  });

  const [files, setFiles] = useState({
    tenthDocument: null as File | null,
    twelfthDocument: null as File | null,
    degreeResultFile: null as File | null,
    video: null as File | null,
    resume: null as File | null,
    coverletter: null as File | null,
  });

  const [errors, setErrors] = useState({
    tenthDocument: '',
    twelfthDocument: '',
    degreeResultFile: '',
    video: '',
    resume: '',
    coverletter: '',
  });

  const [submissionMessage, setSubmissionMessage] = useState('');

  const [models, setModels] = useState<Array<{ id: number; name: string }>>([]);
  const [content, setContent] = useState<Content>({
    text: '',
    imgSrc: '',
    details: '',
  });

  const [showForm, setShowForm] = useState(false); // State to control the visibility of the form

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID'); // Retrieve IIMSTC_ID from local storage
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setFormData((prevFormData) => ({
            ...prevFormData,
            userId: response.data.userId, // Set userId from response
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID', error);
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    // Fetch internship models from the API
    const fetchModels = async () => {
      try {
        const response = await axios.get('/api/internship-model/all'); // Adjust API endpoint as needed
        setModels(response.data);
      } catch (error) {
        console.error('Error fetching internship models:', error);
      }
    };

    fetchModels();
  }, []);

  useEffect(() => {
    // Initialize content for the '4-6' button
    handleButtonClick('4-6');
  }, []);

  const handleButtonClick = (buttonType: '4-6') => {
    setContent({
      text: '',
      imgSrc:
        buttonType === '4-6'
          ?  'https://ams-multitenant-prod.s3.ap-south-1.amazonaws.com/egenius_multitenant-s3/c8d31366-60d7-4c2b-8c4f-627911d8bdb1/64e9946a-b35d-4d09-a41d-0db31520c180/b2ee9dbb-20d6-4530-ad92-6e3c1503244c/Event/bnMBdVOmYl5vcE6e1yBO1D51S53HTfXTToscJ1c9.jpg'
          : '',
      details:
        buttonType === '4-6'
          ? `
          <h3 class="text-xl font-semibold text-blue-600">Model 1: For the Passed out Students</h3>
        <p class="text-base text-gray-700 leading-relaxed">
          <strong>Total Fee:</strong> ₹85,000
        </p>
        <ul class="list-none text-base text-gray-700 ml-4">
          <li><strong>Student Fee:</strong> ₹15,000</li>
          <li><strong>Council Scholarship:</strong> ₹70,000</li>
        </ul>
        <h4 class="text-lg font-semibold text-blue-500">Structure:</h4>
        <ul class="list-decimal list-inside text-base text-gray-700 ml-4">
          <li>Month 1: Soft Skill Training</li>
          <li>Month 2: Work with Industry (Stipend: ₹5,000)</li>
          <li>Month 3 Onwards: Paid Position as per Industry Standards</li>
        </ul>
        `
          : '',
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, files: selectedFiles } = e.target;
    const file = selectedFiles ? selectedFiles[0] : null;
    let errorMessage = '';

    if (file) {
      // Define file size limits
      const fileSizeLimit = name === 'video' ? 50 * 1024 * 1024 : 1 * 1024 * 1024; // 50MB for video, 1MB for other files

      if (file.size > fileSizeLimit) {
        errorMessage = `File size exceeds limit. ${name === 'video' ? 'Video' : 'Document'} should be less than ${name === 'video' ? '50MB' : '1MB'}.`;
      } else {
        errorMessage = '';
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: errorMessage,
      }));

      if (!errorMessage) {
        setFiles((prevFiles) => ({
          ...prevFiles,
          [name]: file,
        }));
      }
    }
  };

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData({ ...formData, modelId: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formDataToSubmit = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataToSubmit.append(key, formData[key as keyof typeof formData]);
    });

    Object.keys(files).forEach((key) => {
      const file = files[key as keyof typeof files];
      if (file) {
        formDataToSubmit.append(key, file);
      }
    });

    try {
      const response = await axios.post('/api/prescreening/create', formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('API Response:', response.data);
      setSubmissionMessage('We will get back to you soon! Thank you for submitting your pre-screening form.');

      // Handle success message
      if (response.data && response.data.message) {
        console.log('Success message:', response.data.message);
        alert('Form submitted successfully!');

        // Store modelId in localStorage if present
        if (response.data.modelId) {
          localStorage.setItem('modelId', response.data.modelId);
          console.log('Model ID stored in localStorage:', response.data.modelId);
        } else {
          console.warn('Model ID not found in response.');
        }

        // Optionally, reset form fields if needed
        setFormData({
          tenthBoard: '',
          tenthYear: '',
          tenthPercentage: '',
          twelfthBoard: '',
          twelfthYear: '',
          twelfthPercentage: '',
          degreeUniversity: '',
          degreeLastSemResult: '',
          userId: '',
          modelId: '', // Reset modelId
        });
        setFiles({
          tenthDocument: null,
          twelfthDocument: null,
          degreeResultFile: null,
          video: null,
          resume: null,
          coverletter: null,
        });
        setErrors({
          tenthDocument: '',
          twelfthDocument: '',
          degreeResultFile: '',
          video: '',
          resume: '',
          coverletter: '',
        });
      } else {
        console.error('Unexpected response format:', response.data);
        alert('Unexpected response format. Please try again.');
      }
    } catch (error) {
      console.error('Error creating PreScreening:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <div className="p-6 bg-white ">
      {!showForm ? (
        <>
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-6">
            Welcome to the SPRINT International Internship Program
          </h2>

          <div className="text-gray-700 leading-relaxed mb-6">
            <p className="text-lg font-medium text-gray-800">
              Transform Your Career with Global Internship Opportunities
            </p>
            <p className="text-base text-gray-700 leading-relaxed">
              Step into a world of opportunities with the SPRINT Program, a unique internship initiative designed to provide undergraduates and graduates with unparalleled international exposure. Our program bridges the gap between academic knowledge and real-world experience, preparing you for a successful professional journey or entrepreneurial endeavors.
            </p>
            <h3 className="text-xl font-semibold text-blue-600">Program Highlights</h3>
            <ul className="list-disc list-inside text-base text-gray-700 ml-4">
              <li>Collaborate with international companies on live projects.</li>
              <li>Gain hands-on experience under the guidance of industry leaders.</li>
              <li>Work part-time or full-time with a stipend ranging from ₹30,000 to ₹50,000 per month.</li>
            </ul>
          </div>

          <div className="flex flex-col md:flex-row md:space-x-6">
            <div className="w-full md:w-1/2">
              <div
                dangerouslySetInnerHTML={{ __html: content.details }}
                className="text-gray-700 leading-relaxed"
              />
              <button
                onClick={() => setShowForm(true)}
                className="w-full bg-teal-600 hover:bg-teal-700 text-white font-bold py-3 px-6 rounded-full mt-6"
              >
                Enroll Now
              </button>
            </div>
            <div className="w-full md:w-1/2 mb-4 md:mb-0">
              <img
                src={content.imgSrc}
                alt="Model 3 Image"
                className="rounded-lg shadow-md"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-white p-6 max-w-7xl mx-auto">
          <div className="flex justify-center">
  <h1 className="text-4xl font-semibold mb-4">Prescreeing Evaluation Process</h1>
</div>
  <h3 className="text-2xl font-semibold mb-4">Quantitative Shortlisting Guideline</h3>
  <p className="mb-6">
    Objective: The main objective of this document is to share the general guidelines for shortlisting prospective applicants to SPRINT international internship program.
  </p>

  <h4 className="text-xl font-semibold mb-2">10th Grade Mark</h4>
  <p>The associated boards for 10th grade mark in the order of significance are IB, IGCSE, CBSE, ICSE, and State.</p>
  <ul className="list-disc pl-6 mb-6">
    <li>State minimum score: 80%</li>
    <li>ICSE and CBSE minimum score: 70%</li>
    <li>IGCSE and IB minimum score: 60%</li>
  </ul>

  <h4 className="text-xl font-semibold mb-2">12th Grade Mark</h4>
  <ul className="list-disc pl-6 mb-6">
    <li>State minimum score: 90%</li>
    <li>ICSE and CBSE minimum score: 80%</li>
    <li>IGCSE and IB minimum score: 70%</li>
  </ul>

  <h4 className="text-xl font-semibold mb-2">CGPA</h4>
  <p>CGPA is collected in percentage to take account varying maximum values.</p>
  <ul className="list-disc pl-6 mb-6">
    <li>CGPA minimum score: 75%</li>
    <li>Those who are in between 60%-75% are waitlisted.</li>
  </ul>
  <p>For waitlisted students, internal marks and external marks for each course in the CGPA will be scrutinized to make decisions.</p>
</div>

<form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-lg max-w-7xl mx-auto mt-8">
  <h3 className="text-2xl font-bold mb-6 text-gray-800">Pre-Screening Form</h3>

  <table className="w-full mb-6 border-collapse border border-gray-300">
    <tbody>
      <tr>
        <td className="p-4 border border-gray-300">
        <label htmlFor="tenthBoard" className="block text-gray-700 font-bold mb-2">10th Board</label>
<select
  id="tenthBoard"
  name="tenthBoard"
  value={formData.tenthBoard}
  onChange={handleChange}
  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
>
  <option value="">Select Board</option>
  <option value="IB">IB</option>
  <option value="IGCSE">IGCSE</option>
  <option value="CBSE">CBSE</option>
  <option value="ICSE">ICSE</option>
  <option value="State">State</option>
</select>

          <label htmlFor="tenthYear" className="block text-gray-700 font-bold mb-2 mt-4">10th Passing Year</label>
          <input
            type="text"
            id="tenthYear"
            name="tenthYear"
            value={formData.tenthYear}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />

          <label htmlFor="tenthPercentage" className="block text-gray-700 font-bold mb-2 mt-4">10th Percentage</label>
          <input
            type="text"
            id="tenthPercentage"
            name="tenthPercentage"
            value={formData.tenthPercentage}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </td>
      </tr>

      <tr>
        <td className="p-4 border border-gray-300">
        <label htmlFor="twelfthBoard" className="block text-gray-700 font-bold mb-2 mt-4">12th Board</label>
<select
  id="twelfthBoard"
  name="twelfthBoard"
  value={formData.twelfthBoard}
  onChange={handleChange}
  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
>
  <option value="">Select Board</option>
  <option value="IB">IB</option>
  <option value="IGCSE">IGCSE</option>
  <option value="CBSE">CBSE</option>
  <option value="ICSE">ICSE</option>
  <option value="State">State</option>
</select>


          <label htmlFor="twelfthYear" className="block text-gray-700 font-bold mb-2 mt-4">12th Passing Year</label>
          <input
            type="text"
            id="twelfthYear"
            name="twelfthYear"
            value={formData.twelfthYear}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />

          <label htmlFor="twelfthPercentage" className="block text-gray-700 font-bold mb-2 mt-4">12th Percentage</label>
          <input
            type="text"
            id="twelfthPercentage"
            name="twelfthPercentage"
            value={formData.twelfthPercentage}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </td>
      </tr>

      <tr>
        <td className="p-4 border border-gray-300">
          <label htmlFor="degreeUniversity" className="block text-gray-700 font-bold mb-2">Degree University</label>
          <input
            type="text"
            id="degreeUniversity"
            name="degreeUniversity"
            value={formData.degreeUniversity}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />

          <label htmlFor="degreeLastSemResult" className="block text-gray-700 font-bold mb-2 mt-4">Last Semester Result</label>
          <input
            type="text"
            id="degreeLastSemResult"
            name="degreeLastSemResult"
            value={formData.degreeLastSemResult}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </td>
      </tr>
    </tbody>
  </table>

  <h3 className="text-2xl font-semibold text-gray-800 mb-6">Upload Documents</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="tenthDocument" className="block text-gray-700 font-bold mb-2">10th Document</label>
          <input
            type="file"
            id="tenthDocument"
            name="tenthDocument"
            onChange={handleFileChange}
            className="w-full p-3 border rounded-lg shadow-sm"
          />
          {errors.tenthDocument && <p className="text-red-500 mt-2">{errors.tenthDocument}</p>}
        </div>
        
        <div>
          <label htmlFor="twelfthDocument" className="block text-gray-700 font-bold mb-2">12th Document</label>
          <input
            type="file"
            id="twelfthDocument"
            name="twelfthDocument"
            onChange={handleFileChange}
            className="w-full p-3 border rounded-lg shadow-sm"
          />
          {errors.twelfthDocument && <p className="text-red-500 mt-2">{errors.twelfthDocument}</p>}
        </div>
        
        <div>
          <label htmlFor="degreeResultFile" className="block text-gray-700 font-bold mb-2">Degree Result File</label>
          <input
            type="file"
            id="degreeResultFile"
            name="degreeResultFile"
            onChange={handleFileChange}
            className="w-full p-3 border rounded-lg shadow-sm"
          />
          {errors.degreeResultFile && <p className="text-red-500 mt-2">{errors.degreeResultFile}</p>}
        </div>
        
        <div>
          <label htmlFor="resume" className="block text-gray-700 font-bold mb-2">Resume</label>
          <input
            type="file"
            id="resume"
            name="resume"
            onChange={handleFileChange}
            className="w-full p-3 border rounded-lg shadow-sm"
          />
          {errors.resume && <p className="text-red-500 mt-2">{errors.resume}</p>}
        </div>
        
        <div>
          <label htmlFor="coverletter" className="block text-gray-700 font-bold mb-2">Cover Letter</label>
          <input
            type="file"
            id="coverletter"
            name="coverletter"
            onChange={handleFileChange}
            className="w-full p-3 border rounded-lg shadow-sm"
          />
          {errors.coverletter && <p className="text-red-500 mt-2">{errors.coverletter}</p>}
        </div>
        
        <div>
          <label htmlFor="video" className="block text-gray-700 font-bold mb-2">Video</label>
          <input
            type="file"
            id="video"
            name="video"
            onChange={handleFileChange}
            className="w-full p-3 border rounded-lg shadow-sm"
          />
          {errors.video && <p className="text-red-500 mt-2">{errors.video}</p>}
        </div>
  </div>

  <div className="mt-6">
  <label htmlFor="modelId" className="block text-gray-700 font-bold mb-2">Select Model</label>
  <select
    id="modelId"
    name="modelId"
    value={formData.modelId || 1} // Default to 1 if formData.modelId is not set
    onChange={handleModelChange}
    className="w-full p-3 border rounded-lg shadow-sm"
    disabled // Disable the select element
  >
    <option value="">Select Model</option>
    {models.map((model) => (
      <option key={model.id} value={model.id}>
        {model.name}
      </option>
    ))}
  </select>
</div>


  <div className="mt-6 text-right">
    <button
      type="submit"
      className="bg-teal-500 text-white py-2 px-4 rounded-lg shadow hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500"
    >
      Submit
    </button>
    {submissionMessage && (
            <div className="bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded-lg mb-4">
              {submissionMessage}
  </div>
   )}
   </div>
</form>

        </>
      )}
    </div>
  );
};

export default Graduate;
