import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UserProfile } from '../services/userService';

interface PaymentComponentProps {
  userProfile: UserProfile | null;
  onPaymentComplete: () => Promise<void>;
}

const PaymentComponent: React.FC<PaymentComponentProps> = ({
  userProfile,
  onPaymentComplete,
}) => {
  const [name, setName] = useState('');
  const [paymentMode, setPaymentMode] = useState('creditCard');
  const [utrNo, setUtrNo] = useState('');
  const [amount, setAmount] = useState('');
  const [BankName, setBankName] = useState('');
  const [referenceFile, setReferenceFile] = useState<File | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modelDescription, setModelDescription] = useState<string[]>([]);
  const [paymentMessage, setPaymentMessage] = useState('');
  useEffect(() => {
    if (isSubmitted) {
      setPaymentMessage('Thanks for your payment. We will get back to you soon.');
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (!userProfile) {
      console.error('User profile is not available');
      return;
    }

    const fetchModelDescription = async () => {
      const modelId = localStorage.getItem('modelId');
      if (modelId) {
        try {
          const response = await axios.get(`/api/internship-model/${modelId}`);
          const description = response.data.description;

          // Split the description into individual lines
          const descriptionLines = description.split('\n');
          setModelDescription(descriptionLines);
        } catch (error) {
          console.error('Error fetching model description:', error);
        }
      }
    };

    fetchModelDescription();
  }, [userProfile]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    if (!userProfile) {
      console.error('User profile is not available');
      return;
    }
  
    if (!userProfile.id) {
      console.error('User ID is not available');
      return;
    }
  
    const formData = new FormData();
    formData.append('name', name);
    formData.append('paymentMode', paymentMode);
    formData.append('utrNo', utrNo);
    formData.append('BankName', BankName);
    formData.append('amount', amount);
    formData.append('userId', userProfile.id.toString());
  
    if (referenceFile) {
      const fileName = referenceFile.name;
      const fileExtension = fileName.split('.').pop() || ''; // Provide a default value if split returns undefined
      formData.append('referenceFile', referenceFile);
      formData.append('fileName', fileName);
      formData.append('fileExtension', fileExtension);
    }
    
  
    try {
      const response = await axios.post('/api/payments/submit', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Payment created successfully', response.data);
      setIsSubmitted(true);
      await onPaymentComplete();
    } catch (error) {
      console.error('Error creating payment', error);
    }
  };

  return (
    <div className="p-8 bg-white">
      <h1 className="text-3xl font-bold text-center text-indigo-800 mb-8">
        SPRINT PROGRAM Payment Process
      </h1>
    
      {/* Table with model description, blank space, and QR code */}
      <table className="w-full mb-8 border border-gray-300">
        <tbody>
          <tr>
            {/* Model Description */}
            <td className="align-top p-4 border border-gray-300">
              {modelDescription.length > 0 && (
                <ul className="text-gray-800 text-xl font-semibold space-y-2">
                  {modelDescription.map((line, index) => (
                    <li key={index}>{line}</li>
                  ))}
                </ul>
              )}
            </td>
            {/* Blank space */}
            <td className="align-top p-4 border border-gray-300">
              <table className="w-full bg-gray-100 rounded-lg shadow-md p-4 border border-gray-300">
                <tbody>
                  <tr>
                    <td className="font-bold text-gray-700 p-2 border border-gray-300">Account Number:</td>
                    <td className="text-gray-600 p-2 border border-gray-300">1234567890</td>
                  </tr>
                  <tr>
                    <td className="font-bold text-gray-700 p-2 border border-gray-300">IFSC CODE:</td>
                    <td className="text-gray-600 p-2 border border-gray-300">ABCD0123456</td>
                  </tr>
                  <tr>
                    <td className="font-bold text-gray-700 p-2 border border-gray-300">Bank Name:</td>
                    <td className="text-gray-600 p-2 border border-gray-300">Example Bank</td>
                  </tr>
                  <tr>
                    <td className="font-bold text-gray-700 p-2 border border-gray-300">Branch:</td>
                    <td className="text-gray-600 p-2 border border-gray-300">XYZ Branch</td>
                  </tr>
                </tbody>
              </table>
            </td>
            {/* QR Code */}
            <td className="align-top p-4 border border-gray-300">
              <div className="relative w-full h-full bg-gradient-to-br from-gray-200 via-gray-300 to-gray-400 rounded-lg overflow-hidden">
                <div className="absolute inset-0 bg-black opacity-50 blur-sm"></div>
                <div className="relative z-10 flex flex-col items-center justify-center h-full p-6">
                  <div className="w-32 h-32 bg-gray-500 flex items-center justify-center rounded-full">
                    <span className="text-white text-lg font-bold">QR Code</span>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Payment Form */}
      <div className="bg-gradient-to-br from-indigo-100 via-indigo-200 to-indigo-300 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800 mb-6">Payment Information</h2>
        <form onSubmit={handleSubmit} className="space-y-5">
          {/* Form Fields */}
          <div className="flex flex-col">
            <label htmlFor="name" className="text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2 px-3"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="paymentMode" className="text-sm font-medium text-gray-700">Payment Mode</label>
            <select
              id="paymentMode"
              name="paymentMode"
              value={paymentMode}
              onChange={(e) => setPaymentMode(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2 px-3"
            >
              <option value="creditCard">Credit Card</option>
              <option value="paypal">UPI</option>
              <option value="bankTransfer">Bank Transfer</option>
            </select>
          </div>
          <div className="flex flex-col">
            <label htmlFor="utrNo" className="text-sm font-medium text-gray-700">UTR No</label>
            <input
              type="text"
              id="utrNo"
              name="utrNo"
              value={utrNo}
              onChange={(e) => setUtrNo(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2 px-3"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="BankName" className="text-sm font-medium text-gray-700">Bank Name</label>
            <input
              type="text"
              id="BankName"
              name="BankName"
              value={BankName}
              onChange={(e) => setBankName(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2 px-3"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="amount" className="text-sm font-medium text-gray-700">Amount</label>
            <input
              type="text"
              id="amount"
              name="amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2 px-3"
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="referenceFile" className="text-sm font-medium text-gray-700">Reference File</label>
            <input
              type="file"
              id="referenceFile"
              name="referenceFile"
              onChange={(e) => setReferenceFile(e.target.files?.[0] || null)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm py-2 px-3"
            />
          </div>
          {paymentMessage && (
          <div className="bg-green-100 border border-green-500 text-green-700 p-4 rounded-lg mb-4">
            {paymentMessage}
          </div>
        )}
          <button
            type="submit"
            className="block w-full text-center text-white bg-indigo-600 hover:bg-indigo-700 font-bold py-2 px-4 rounded-md shadow-lg"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default PaymentComponent;
