import React, { useState } from 'react';
import axios from 'axios';
import '../styles/accessFormCss.css';

// Define the structure of your formData
interface FormData {
  name: string;
  email: string;
  department: string;
  company: string;
  designation: string;
  managerName: string;
  managerEmail: string;
  phoneNumber: string;
  address: string;
  accessType: string; // Added accessType here
}

const AccessForm: React.FC = () => {
  const [accessType, setAccessType] = useState<string>('');
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    department: '',
    company: '',
    designation: '',
    managerName: '',
    managerEmail: '',
    phoneNumber: '',
    address: '',
    accessType: '' // Initialize accessType here
  });
  const [idCardPhoto, setIdCardPhoto] = useState<File | null>(null);
  const [profilePhoto, setProfilePhoto] = useState<File | null>(null);

  const handleGoBack = () => {
    window.location.href = '../index.html';
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.name === "idCardPhoto") setIdCardPhoto(e.target.files[0]);
      if (e.target.name === "profilePhoto") setProfilePhoto(e.target.files[0]);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  const data = new FormData();
  data.append('name', formData.name);
  data.append('email', formData.email);
  data.append('department', formData.department);
  data.append('company', formData.company);
  data.append('designation', formData.designation);
  data.append('managerName', formData.managerName);
  data.append('managerEmail', formData.managerEmail);
  data.append('phoneNumber', formData.phoneNumber);
  data.append('address', formData.address);
  data.append('accessType', accessType);

  if (idCardPhoto) data.append('idCardPhoto', idCardPhoto);
  if (profilePhoto) data.append('profilePhoto', profilePhoto);

  // Ensure all fields are sent when accessType is Faculty or Industry Facilitator
  const requiredFields: (keyof FormData)[] = [
    'name',
    'email',
    'department',
    'company',
    'designation',
    'managerName',
    'managerEmail',
    'phoneNumber',
    'address'
  ];

  requiredFields.forEach(field => {
    if (!formData[field]) {
      alert(`Please fill out the ${field} field.`);
      return;
    }
  });

  // Validate accessType separately
  if (!accessType) {
    alert(`Please select an access type.`);
    return;
  }

  try {
    // Adjust the API endpoint based on access type
    const apiEndpoint = accessType === 'faculty'
      ? '/api/faculties/faculty-access'
      : '/api/industry/industry-access'; // New endpoint for Industry Facilitator

    const response = await axios.put(apiEndpoint, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    alert(response.data.message);
  } catch (error) {
    console.error("Error submitting form:", error);
    alert('An error occurred while submitting the form.');
  }
};

  return (
    <div className="access-form-container">
      <form id="accessForm" className="access-form" onSubmit={handleSubmit}>
        <fieldset className="form-section">
          <legend className="form-legend">
            <strong>Access Request Form</strong>
          </legend>
          
          <div className="form-row">
            <div className="form-group">
              <label htmlFor="email" className="form-label">Email</label>
              <input id="email" name="email" type="email" value={formData.email} onChange={handleChange} placeholder="Enter Email" className="form-input" required />
            </div>

            <div className="form-group">
              <label htmlFor="accessType" className="form-label">Types of Access</label>
              <select
                id="accessType"
                name="accessType"
                className="form-input"
                required
                value={accessType}
                onChange={(e) => setAccessType(e.target.value)}
              >
                <option value="">Select Access Type</option>
                <option value="industry_facilitator">Industry Facilitator</option>
                <option value="faculty">Faculty</option>
               
              </select>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="name" className="form-label">Name</label>
              <input id="name" name="name" type="text" placeholder="Enter Name" className="form-input" required value={formData.name} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber" className="form-label">Phone number</label>
              <input id="phoneNumber" name="phoneNumber" type="text" placeholder="Enter Phone Number" className="form-input" required value={formData.phoneNumber} onChange={handleChange} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="department" className="form-label">Department</label>
              <input id="department" name="department" type="text" placeholder="Enter Department" className="form-input" required value={formData.department} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="company" className="form-label">Company</label>
              <input id="company" name="company" type="text" placeholder="Enter Company" className="form-input" required value={formData.company} onChange={handleChange} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="designation" className="form-label">Designation</label>
              <input id="designation" name="designation" type="text" placeholder="Enter Designation" className="form-input" required value={formData.designation} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="managerName" className="form-label">Manager Name</label>
              <input id="managerName" name="managerName" type="text" placeholder="Enter Manager Name" className="form-input" value={formData.managerName} onChange={handleChange} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="managerEmail" className="form-label">Manager Email</label>
              <input id="managerEmail" name="managerEmail" type="email" placeholder="Enter Manager Email" className="form-input" value={formData.managerEmail} onChange={handleChange} />
            </div>
            <div className="form-group">
              <label htmlFor="address" className="form-label">Address</label>
              <input id="address" name="address" type="text" placeholder="Enter Address" className="form-input" required value={formData.address} onChange={handleChange} />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label htmlFor="idCardPhoto" className="form-label">ID Card Photo</label>
              <input id="idCardPhoto" name="idCardPhoto" type="file" className="form-input" accept="image/*" onChange={handleFileChange} required />
            </div>
            <div className="form-group">
              <label htmlFor="profilePhoto" className="form-label">Profile Photo</label>
              <input id="profilePhoto" name="profilePhoto" type="file" className="form-input" accept="image/*" onChange={handleFileChange} required />
            </div>
          </div>

          <div className="access-form-btn-container">
            <button type="submit" className="form-btn access-form-btn-submit">Submit</button>
            <button type="button" className="form-btn access-form-btn-cancel" onClick={handleGoBack}>Go Back</button>
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default AccessForm;
