import React, { useState, ChangeEvent, FormEvent } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/log.css';  // Assuming styles are in log.css

const Login: React.FC = () => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string | null>(null); // State for error handling
    const navigate = useNavigate();

    const handleGoBack = () => {
        // Navigate back to the registration page
        navigate('/reg');
    };

    const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            // Send the login request with username and password
            const response = await axios.post('/api/auth/login', { username, password });
            const { token, adminId } = response.data;  // Destructure token and adminId from the response

            // Store the token and adminId in localStorage
            localStorage.setItem('token', token);
            localStorage.setItem('adminId', adminId);

            // Navigate to the dashboard after successful login
            navigate('/dashboard');
        } catch (err) {
            console.error('Login failed', err);
            setError('Invalid username or password'); // Set error message on failure
        }
    };

    const handleUsernameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    return (
        <div className="login-container">
            <form id="loginForm" className="login-form" onSubmit={handleLogin}>
                <fieldset className="login-form-section">
                    <legend className="login-form-legend">
                        <span className="login-text"><strong>LOGIN</strong></span>
                    </legend>

                    {error && <div className="error-message">{error}</div>}  {/* Show error message if login fails */}

                    <div className="login-form-group">
                        <label htmlFor="username" className="login-form-label">Username</label>
                        <input 
                            id="username" 
                            name="username" 
                            type="text" 
                            value={username}
                            onChange={handleUsernameChange}
                            placeholder="Username" 
                            className="login-form-input" 
                            required 
                        />
                    </div>

                    <div className="login-form-group">
                        <label htmlFor="password" className="login-form-label">Password</label>
                        <input 
                            id="password" 
                            name="password" 
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Password" 
                            className="login-form-input" 
                            required 
                        />
                    </div>
                </fieldset>

                <div className="login-form-btn-container">
                    <button 
                        type="submit" 
                        className="login-form-btn login-form-btn-submit"
                    >
                        Login
                    </button>
                    <button 
                        type="button" 
                        className="login-form-btn login-form-btn-go-back" 
                        onClick={handleGoBack}
                    >
                        Go Back
                    </button>
                </div>

                <div className="login-additional-links">
                    <p>Don't have an account? <a href="/register" className="login-link">Register Now</a></p>
                </div>
            </form>
        </div>
    );
};

export default Login;
