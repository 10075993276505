import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface DailyLog {
  id?: number;
  day: string;
  date: string;
  arrivalTime: string;
  departureTime: string;
  remarks: string;
  department: string;
  finishedProduct: string;
  hodName: string;
  hodEmail: string;
  mainPoints: string;
  userId: string;
}

interface DailyLogsProps {
  aicteInternId: number;
  onLogSubmit: (log: DailyLog) => Promise<void>;
  initialLog: DailyLog | null;
  mode: 'create' | 'edit' | 'view';
  readOnly?: boolean;
  selectedDate?: string; // Add this line to accept the selected date
}


const DailyLogForm: React.FC<DailyLogsProps> = ({ aicteInternId, onLogSubmit, initialLog, mode, readOnly = false }) => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0]; // Format date to YYYY-MM-DD

  const [log, setLog] = useState<DailyLog>({
    id: initialLog?.id,
    day: initialLog?.day || '',
    date: initialLog?.date || formattedDate, // Set today's date here
    arrivalTime: initialLog?.arrivalTime || '',
    departureTime: initialLog?.departureTime || '',
    remarks: initialLog?.remarks || '',
    department: initialLog?.department || '',
    finishedProduct: initialLog?.finishedProduct || '',
    hodName: initialLog?.hodName || '',
    hodEmail: initialLog?.hodEmail || '',
    mainPoints: initialLog?.mainPoints || '',
    userId: '',
  });


  // Set isMaximized to true by default
  const [isMaximized, setIsMaximized] = useState(true);
  const [aicteIntern, setAicteIntern] = useState<{ hodName: string; hodEmail: string } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
const [isEditing, setIsEditing] = useState(mode === 'edit');

  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  }

  useEffect(() => {
    const fetchUserId = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setLog((prevLog) => ({
            ...prevLog,
            userId: response.data.userId,
          }));
        }
      } catch (error) {
        console.error('Error fetching user ID:', error);
        setError('Failed to fetch user ID. Please try again.');
      }
    };

    fetchUserId();
  }, []);

  useEffect(() => {
    const fetchFacultyInfo = async () => {
      try {
        const { userId } = log;
        if (userId) {
          // Fetch faculty information by userId
          const response = await axios.get(`/api/aicte-interns/faculty/${userId}`);
          setLog((prevLog) => ({
            ...prevLog,
            hodName: response.data.hodName,
            hodEmail: response.data.hodEmail,
          }));
        }
      } catch (error) {
        const typedError = error as any; // Type assertion
        if (typedError.response && typedError.response.status === 404) {
          setError('Faculty information not found.');
        } else {
          setError('An error occurred while fetching faculty information.');
        }
        console.error('Error fetching faculty information:', typedError);
      }
    };

    fetchFacultyInfo();
  }, [log.userId]);


  useEffect(() => {
    const fetchDailyLog = async () => {
      try {
        if (aicteInternId && log.date && log.userId) {
          const response = await axios.get(`/api/daily-logs/${aicteInternId}/${log.date}`);
          if (response.data && response.data.dailyLog) {
            setLog(response.data.dailyLog);
          }
        }
      } catch (error) {
        console.error('Error fetching existing daily log:', error);

      } finally {
        setLoading(false);
      }
    };

    fetchDailyLog();
  }, [aicteInternId, log.date, log.userId]);

  useEffect(() => {
    if (aicteIntern) {
      setLog((prevLog) => ({
        ...prevLog,
        hodName: aicteIntern.hodName,
        hodEmail: aicteIntern.hodEmail,
      }));
    }
  }, [aicteIntern]);

  useEffect(() => {
    if (initialLog) {
      setLog((prevLog) => ({
        ...prevLog,
        ...initialLog,
      }));
    }
  }, [initialLog]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setLog((prevLog) => ({
      ...prevLog,
      [name]: value,
    }));
  };

 const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.preventDefault();
    alert('Pasting is not allowed in this field.');
  };

  const validateForm = () => {
    if (
      !log.day ||
      !log.date ||
      !log.arrivalTime ||
      !log.departureTime ||
      !log.remarks ||
      !log.department ||
      !log.finishedProduct ||
      !log.mainPoints
    ) {
      setError('Please fill out all required fields.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    setError(null);

    try {
      const logToSave = {
        ...log,
        id: log.id?.toString(),
      };

      if (log.id) {
        await axios.put(`/api/daily-logs/daily-log/${log.userId}/${log.date}`, logToSave);
        alert('Daily log updated successfully');
      } else {
        await axios.post('/api/daily-logs/user', logToSave);
        alert('Daily log created successfully');
        setLog({
          id: undefined,
          day: '',
          date: '',
          arrivalTime: '',
          departureTime: '',
          remarks: '',
          department: '',
          finishedProduct: '',
          hodName: '',
          hodEmail: '',
          mainPoints: '',
          userId: '',
        });
      }
      onLogSubmit(log);
      setIsEditing(false); // Reset edit mode after submission
    } catch (error) {
      console.error('Error submitting daily log:', error);
      setError('Failed to submit the log. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="spinner-border  animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"></div>
      </div>
    );
  }


  return (

    <div
      className={`flex justify-center items-center ml-[25px] rounded-md min-h-screen ${isMaximized ? 'w-full h-full' : 'w-[850px] h-[830px]'}`}
      style={{ transform: 'translateX(-16px)' }}
    >
      <form
        onSubmit={handleSubmit}
        className={`p-4 bg-white shadow-md rounded-md transition-all duration-300 ${isMaximized ? 'w-full h-full' : 'w-[900px] h-[830px]'} sm:w-full`}
      >
         <div className="flex justify-between mb-4">
        <h2 className="text-lg font-medium">Daily Log Form</h2>
        {mode !== 'view' && (
          <button
            type="button"
            onClick={() => setIsEditing(!isEditing)}
            className="text-sm text-blue-600 hover:underline"
          >
            {isEditing ? 'Cancel Edit' : 'Edit'}
          </button>
        )}
      </div>

      {error && <div className="text-red-500 text-sm mb-4">{error}</div>}


        {/* Form fields in responsive grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label htmlFor="day" className="block text-base font-bold text-gray-700">Day</label>
            <input
              type="text"
              id="day"
              name="day"
              value={log.day}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
             disabled={readOnly || !isEditing}
            />
          </div>
          <div>
            <label htmlFor="date" className="block text-base font-bold text-gray-700">Date</label>
            <input
              type="date"
              id="date"
              name="date"
              value={log.date}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              disabled={readOnly || !isEditing}
            />
          </div>
          <div>
            <label htmlFor="arrivalTime" className="block text-base font-bold text-gray-700">Login</label>
            <input
              type="time"
              id="arrivalTime"
              name="arrivalTime"
              value={log.arrivalTime}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              disabled={readOnly || !isEditing}
            />
          </div>
          <div>
            <label htmlFor="departureTime" className="block text-base font-bold text-gray-700">Logout</label>
            <input
              type="time"
              id="departureTime"
              name="departureTime"
              value={log.departureTime}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              disabled={readOnly || !isEditing}
            />
          </div>
          
          <div>
            <label htmlFor="department" className="block text-base font-bold text-gray-700">Organization</label>
            <input
              type="text"
              id="department"
              name="department"
              value={log.department}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
             disabled={readOnly || !isEditing}
            />
          </div>
          <div>
            <label htmlFor="finishedProduct" className="block text-base font-bold text-gray-700">Project Name</label>
            <input
              type="text"
              id="finishedProduct"
              name="finishedProduct"
              value={log.finishedProduct}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              disabled={readOnly || !isEditing}
            />
          </div>
          <div>
            <label htmlFor="hodName" className="block text-base font-bold text-gray-700">HOD Name</label>
            <input
              type="text"
              id="hodName"
              name="hodName"
              value={log.hodName}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
             disabled={readOnly || !isEditing}
            />
          </div>
          <div>
            <label htmlFor="hodEmail" className="block text-base font-bold text-gray-700">HOD Email</label>
            <input
              type="email"
              id="hodEmail"
              name="hodEmail"
              value={log.hodEmail}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-10 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              disabled={readOnly || !isEditing}
            />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <label htmlFor="mainPoints" className="block text-base font-bold text-gray-700">Task Highlights </label>
            <p className="text-sm text-gray-600 mt-1">(Provide 2 lines overview of the task of the day.)</p>
            <textarea
              id="mainPoints"
              name="mainPoints"
              value={log.mainPoints}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-20 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              disabled={readOnly || !isEditing}
            ></textarea>
          </div>
          <div className="col-span-1 sm:col-span-2">
            <label htmlFor="remarks" className="block text-base font-bold text-gray-700">In-Depth Update </label>
            <p className="text-sm text-gray-600 mt-1">(Provide detailed descriptive information of the task for the day.)</p>
            <textarea
              id="remarks"
              name="remarks"
              value={log.remarks}
              onChange={handleChange}
              onPaste={handlePaste}
              className="mt-1 block w-full h-20 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              disabled={readOnly || !isEditing}
            ></textarea>
          </div>
        </div>

        {/* Buttons */}
         {mode !== 'view' && !readOnly && (
        <div className="mt-4 flex flex-col items-end space-y-2">
          <div className="flex justify-end space-x-2">
            {isEditing ? (
              <>
                <button
                  type="button"
                  onClick={() => setIsEditing(false)}
                  className="bg-gray-400 text-white font-medium py-1 px-3 rounded-md hover:bg-gray-300 focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-green-600 text-white font-medium py-1 px-3 rounded-md hover:bg-green-500 focus:outline-none focus:bg-green-500"
                >
                  Update
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={() => setIsEditing(true)}
                className="bg-red-600 text-white font-medium py-1 px-3 rounded-md hover:bg-red-500 focus:outline-none focus:bg-red-500"
              >
                Edit
              </button>
            )}
          </div>

            {/* Checkbox for Mark Internship as Complete */}
            <div className="flex items-center text-sm flex-wrap">
              <input
                type="checkbox"
                id="markComplete"
                className="mr-2 leading-tight w-6"
              />
              <label htmlFor="markComplete" className="text-gray-700 text-sm pt-2">
                Mark Internship as Complete
              </label>
            </div>
          </div>
        )}

      </form>
    </div>
  );
};

export default DailyLogForm;
