import React, { useEffect, useState } from 'react';
import '../styles/Flowchart.css';
import { useNavigate } from 'react-router-dom';
import saikiranPDF from '../img/Ansh_Report.pdf';
import StudentFeedback from './StudentFeedback';
import axios from 'axios';

const Flowchart: React.FC = () => {
  const [assessmentClicked, setAssessmentClicked] = useState(false);
  const [feedbackClicked, setFeedbackClicked] = useState(false);
  const [paraColor, setParaColor] = useState('#2e6ca6'); // Initial color for paragraphs
  const [feedbackGiven, setFeedbackGiven] = useState(false); // Feedback status from the backend
  const [downloadMessage, setDownloadMessage] = useState(''); // Message below Generate Report
  const navigate = useNavigate();
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [id, setid] = useState<number | null>(null); // State to store user ID
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isFeedbackPopupVisible, setIsFeedbackPopupVisible] = useState(false);

  useEffect(() => {
    const fetchid = async () => {
      try {
        const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
        if (IIMSTC_ID) {
          const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
          setid(response.data.id); // Set user ID state
        }
      } catch (error) {
        console.error('Error fetching user ID', error);
      }
    };

    fetchid();
  }, []);

  useEffect(() => {
    const fetchFeedbackStatus = async () => {
      if (id) {
        try {
          const response = await axios.get(`/api/users/feedback-status/${id}`);
          setFeedbackGiven(response.data.feedbackGiven);
        } catch (error) {
          console.error("Error fetching feedback status:", error);
        }
      }
    };

    fetchFeedbackStatus();
  }, [id]);

  const handleGenerateReport = () => {
    const link = document.createElement('a');
    link.href = saikiranPDF;
    link.download = 'Ansh_Report.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setAssessmentClicked(true); // Change assessment button color to green
  };

  return (
    <div className="flex flex-col items-center justify-center space-y-8 p-8 border border-gray-300 rounded-lg">
      {/* Feedback Button */}
      <div className="w-full flex justify-center">
        <button
          className={`w-full max-w-xs px-4 py-2 rounded-lg text-white ${
            feedbackGiven ? 'bg-green-500' : 'bg-blue-500'
          }`}
          onClick={() => !feedbackGiven && setIsFeedbackPopupVisible(true)}
          disabled={feedbackGiven}
        >
          {feedbackGiven ? 'Feedback Submitted' : 'Student Feedback'}
        </button>

        {isFeedbackPopupVisible && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative bg-white p-6 rounded-lg shadow-lg max-w-lg w-full h-[80vh] overflow-y-auto">
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-4xl"
                onClick={() => setIsFeedbackPopupVisible(false)}
              >
                &times;
              </button>
              <StudentFeedback />
            </div>
          </div>
        )}
      </div>

      {/* Middle Section: Arrow and Paragraph Side by Side */}
      <div className="flex justify-center items-center space-x-4 ml-[30%]">
        <div className="flex items-center justify-center">
          <span className="text-5xl h-12">⬇</span>
        </div>
        <div className="flex items-center justify-center">
          {!feedbackSubmitted ? (
            <p className="text-red-500">Give feedback to Faculty Program</p>
          ) : (
            <p className="text-green-500">Feedback Submitted</p>
          )}
        </div>
      </div>

      <div className="w-full flex justify-center">
        <p className="w-full max-w-xs px-4 py-2 rounded-lg text-black text-center"
          style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Faculty Mentor Feedback
        </p>
      </div>
 <div className="flex justify-center items-center space-x-4 ml-[30%]">
        <div className="flex items-center justify-center">
          <span className="text-5xl h-12">⬇</span>
        </div>
        <div className="flex items-center justify-center">
          {!feedbackSubmitted ? (
            <p className="text-red-500">Give feedback to Industry & Internship Program</p>
          ) : (
            <p className="text-green-500">Feedback Submitted</p>
          )}
        </div>
      </div>

      <div className="w-full flex justify-center">
        <p className="w-full max-w-xs px-4 py-2 rounded-lg text-black text-center"
          style={{ fontSize: '1rem', fontWeight: 'bold' }}>
          Industry Mentor Feedback
        </p>
      </div>
      {/* Download Report Button */}
      <div className="w-full flex justify-center">
        <button
          className="w-full max-w-xs px-4 py-2 bg-green-500 text-white rounded-lg"
          onClick={handleGenerateReport}
        >
          Download Report
        </button>
      </div>
    </div>
  );
};

export default Flowchart;
