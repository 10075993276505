import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/FullCalendarComponent.css';

const StudentAttendanceCalendar: React.FC = () => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [events, setEvents] = useState<Array<any>>([]);
    const [userId, setUserId] = useState<number | null>(null);
    const [submittedLogDates, setSubmittedLogDates] = useState<Set<string>>(new Set());
    const navigate = useNavigate();

    // Fetch User ID
    useEffect(() => {
        const fetchUserId = async () => {
            try {
                const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
                if (IIMSTC_ID) {
                    const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
                    setUserId(response.data.userId);
                }
            } catch (error) {
                console.error('Error fetching user ID', error);
            }
        };
        fetchUserId();
    }, []);

    // Fetch Events and Submitted Log Dates
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                if (userId !== null) {
                    const response = await axios.get(`/api/daily-logs/${userId}`);
                    const logs = response.data.dailyLogs;

                    // Use a Set to store unique dates and filter out duplicate events
                    const logDates: Set<string> = new Set(logs.map((log: any) => new Date(log.date).toISOString().split('T')[0]));
                    console.log("LogDates Set:", Array.from(logDates)); // Debug to ensure logDates is populated
                    setSubmittedLogDates(logDates); // Set the submitted log dates in state

                    // Filter and create unique events for the calendar
                    const uniqueEvents = Array.from(logDates).map(date => ({
                        title: 'Present',
                        start: date,
                        allDay: true,
                    }));

                    setEvents(uniqueEvents); // Set the unique events
                }
            } catch (error) {
                console.error('Error fetching logs:', error);
            }
        };

        if (userId !== null) {
            fetchEvents();
        }
    }, [userId]);

    // Handle date click (log submission)
    const handleDateClick = async (arg: any) => {
        const clickedDate = new Date(arg.dateStr);
        setSelectedDate(clickedDate);
    };

    // Day cell styling
    const dayCellDidMount = (info: any) => {
        const cellDate = new Date(info.date).toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
        const today = new Date();

        // Log the cellDate and submittedLogDates to ensure they match
        console.log("Checking cellDate:", cellDate);
        console.log("SubmittedLogDates in Set:", Array.from(submittedLogDates));

        // Style based on submitted log dates
        if (submittedLogDates.has(cellDate)) {
            console.log('CellDate is in submittedLogDates:', cellDate); // Debug when it should turn green
            info.el.style.backgroundColor = 'green'; // Green for dates with submitted logs
        } else if (new Date(info.date) < today) {
            info.el.style.backgroundColor = 'white'; // White for past dates without logs
        } else {
            info.el.style.backgroundColor = 'white'; // White for future dates
        }
    };

    // Event rendering with green background for 'Present' events
    const eventDidMount = (info: any) => {
        if (info.event.title === 'Present') {
            // Make the event background green
            info.el.style.backgroundColor = 'green';
        }
    };

    return (
        <div className="relative max-w-4xl mx-auto p-5 bg-gray-800 text-white rounded-lg shadow-lg">
            <h2 className="text-2xl mb-4">Attendance Calendar</h2>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
                initialView="dayGridMonth"
                selectable={true}
                dateClick={handleDateClick}
                events={events}
                dayCellDidMount={dayCellDidMount}
                eventDidMount={eventDidMount} // Added eventDidMount for custom event styling
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
                }}
            />
            {/* Legend */}
            <div className="flex items-center mt-4 space-x-4">
                <div className="flex items-center space-x-2">
                    <span className="w-3 h-3 rounded-full bg-green-500"></span>
                    <span>Present</span>
                </div>
                <div className="flex items-center space-x-2">
                    <span className="w-3 h-3 rounded-full bg-red-500"></span>
                    <span>Absent</span>
                </div>
                <div className="flex items-center space-x-2">
                    <span className="w-3 h-3 rounded-full bg-yellow-500"></span>
                    <span>Leave applied </span>
                </div>
            </div>
        </div>
    );
};

export default StudentAttendanceCalendar;
