import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Webcam from 'react-webcam';
import axios from 'axios';
interface Question {
  text: string;
  options: string[];
  correctAnswer: number;
  answered: boolean;
  answeredIndex?: number;
}

const Test: React.FC = () => {
  const [test, setTest] = useState<Question[]>([
    {
      text: "What is a correct way to define a state in a functional component using TypeScript?",
      options: [
        "const [state, setState] = useState<number>(0);",
        "const state: number = useState(0);",
        "const [state, setState] = State(0);",
        "let state = new useState(0);"
      ],
      correctAnswer: 1,
      answered: false,
    },
    
    {
      text: "How do you type props in a React component using TypeScript?",
      options: [
        "function Component(props: any): JSX.Element {}",
        "function Component(props: Props): JSX.Element {}",
        "function Component(props: void): JSX.Element {}",
        "function Component(props: any): Element {}"
      ],
      correctAnswer: 2, // Second option is correct
      answered: false,
    },
    {
      text: "Which hook is used to perform side effects in React?",
      options: [
        "useContext",
        "useState",
        "useEffect",
        "useReducer"
      ],
      correctAnswer: 3, // Third option is correct
      answered: false,
    },
    {
      text: "How do you define a type for a function in TypeScript?",
      options: [
        "type MyFunction = (param1: string, param2: number) => void;",
        "interface MyFunction { param1: string, param2: number }",
        "type MyFunction = { param1: string, param2: number }",
        "function MyFunction: string, param2: number {}"
      ],
      correctAnswer: 1, // First option is correct
      answered: false,
    },
    {
      text: "What is the difference between 'interface' and 'type' in TypeScript?",
      options: [
        "'type' can be extended while 'interface' cannot.",
        "'interface' can be extended while 'type' cannot.",
        "'interface' and 'type' are identical in usage.",
        "None of the above."
      ],
      correctAnswer: 2, // Second option is correct
      answered: false,
    },
    {
      text: "How can you handle form data in React using TypeScript?",
      options: [
        "Use state and add types to form handlers.",
        "Use forms without types in TypeScript.",
        "Use form libraries like Redux.",
        "None of the above."
      ],
      correctAnswer: 1, // First option is correct
      answered: false,
    },
    {
      text: "How do you ensure a component in TypeScript can accept children?",
      options: [
        "const Component: React.FC = ({ children }) => {}",
        "const Component = ({ children }: { children: JSX.Element[] }) => {}",
        "const Component = ({ children }: { children: React.ReactNode }) => {}",
        "None of the above."
      ],
      correctAnswer: 3, // Third option is correct
      answered: false,
    },
    {
      text: "Which of the following is true about React useEffect?",
      options: [
        "It runs after every render by default.",
        "It can be configured to run once by passing an empty dependency array.",
        "It can clean up side effects using a return function.",
        "All of the above."
      ],
      correctAnswer: 4, // Fourth option is correct
      answered: false,
    },
    {
      text: "What is the default type for useState when no type is explicitly defined in TypeScript?",
      options: [
        "string",
        "number",
        "any",
        "undefined"
      ],
      correctAnswer: 3, // Third option is correct
      answered: false,
    },
    {
      text: "How do you define optional props in TypeScript?",
      options: [
        "interface Props { name: string? }",
        "type Props = { name?: string }",
        "interface Props { name: string | null }",
        "type Props = { name: string | undefined }"
      ],
      correctAnswer: 2, // Second option is correct
      answered: false,
    },
  ]);

  const [answers, setAnswers] = useState<(number | null)[]>(new Array(test.length).fill(null));
  const [timeLeft, setTimeLeft] = useState<number>(600); // 10 minutes in seconds
  const [testEnded, setTestEnded] = useState<boolean>(false);
  const [tabSwitchCount, setTabSwitchCount] = useState<number>(0);
  const [showWebcam, setShowWebcam] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [showGuidelines, setShowGuidelines] = useState<boolean>(true); // Show guidelines modal
  const [agreed, setAgreed] = useState<boolean>(false); // Agreement checkbox

  const navigate = useNavigate(); // Initialize navigate from react-router-dom

  const webcamRef = useRef<Webcam>(null);

  // Countdown timer logic
  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0 && !testEnded) {
        setTimeLeft(timeLeft - 1);
      } else if (!testEnded) {
        endTest();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, testEnded]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  // Tab switch detection
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setTabSwitchCount(prevCount => prevCount + 1);
        console.log('Tab switch count:', tabSwitchCount + 1);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [tabSwitchCount]);

  useEffect(() => {
    if (tabSwitchCount === 1) {
      alert('You are not allowed to switch tabs during the test.');
    } else if (tabSwitchCount >= 2) {
      alert('Test ended due to multiple tab switches.');
      endTest();
    }
  }, [tabSwitchCount]);

  const handleOptionSelect = (questionIndex: number, optionIndex: number) => {
    if (!test[questionIndex].answered) {
      const updatedAnswers = [...answers];
      updatedAnswers[questionIndex] = optionIndex + 1;
      setAnswers(updatedAnswers);

      const updatedTest = [...test];
      updatedTest[questionIndex].answered = true;
      updatedTest[questionIndex].answeredIndex = optionIndex + 1;
      setTest(updatedTest);
    }
  };


      const [userId, setUserId] = useState<string | null>(null);
const [error, setError] = useState<string | null>(null);

useEffect(() => {
  const fetchUserId = async () => {
    try {
      const IIMSTC_ID = localStorage.getItem('IIMSTC_ID');
      if (IIMSTC_ID) {
        const response = await axios.get(`/api/users/current-user?IIMSTC_ID=${IIMSTC_ID}`);
        setUserId(response.data.userId);
      }
    } catch (error) {
      console.error('Error fetching user ID:', error);
      setError('Failed to fetch user ID. Please try again.');
    }
  };

  fetchUserId();
}, []);

const handleSubmit = async () => {
  console.log('Submit triggered');
  console.log('Test Ended:', testEnded);
  console.log('User ID:', userId);

  // If the test hasn't ended, end the test first
  if (!testEnded) {
    console.log('Ending the test first...');
    setSubmitted(true);
    await endTest();  // Wait for the test to end before proceeding
    return;  // Exit after ending test
  }

  // If the test has ended and we have the user ID, submit the test data
  if (testEnded && userId) {
    try {
      console.log('Submitting test status...');
      const apiUrl = `/api/a/${userId}/test`;
      console.log('API URL:', apiUrl);

      const response = await axios.put(apiUrl, {
        testAttended: true,
      });

      console.log('Test status updated successfully:', response.data);
      alert('Test submission successful!');
    } catch (error) {
      console.error('Error updating test status:', error);
      setError('Failed to update test status. Please try again.');
      alert('Error submitting test.');
    }
  } else {
    console.log('Test has not ended or User ID is missing.');
  }
};

const endTest = async () => {
  console.log('Ending test...');
  setTestEnded(true);
  setShowWebcam(false);  // Stop showing the webcam

  const score = answers.reduce((total, answer, index) => {
    if (index < test.length && test[index]) {
      return answer === test[index].correctAnswer ? (total || 0) + 1 : total || 0;
    }
    return total;
  }, 0);

  console.log(`Test ended. You scored ${score} out of ${test.length}`);
  alert(`You scored ${score} out of ${test.length}`);
};


  const handleStartTest = () => {
    if (agreed) {
      setShowGuidelines(false); // Close the guidelines modal
    } else {
      alert("You need to agree to the guidelines to start the test.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-blue-500 to-purple-700 text-white relative">
      {/* Guidelines Modal */}
      {showGuidelines && (
        <div className="absolute inset-0 bg-gray-900 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 max-w-lg w-full text-gray-900">
            <h2 className="text-2xl font-bold mb-4">Guidelines</h2>
            <ul className="list-disc pl-6 mb-4">
              <li>Do not switch tabs during the test.</li>
              <li>Ensure your webcam is on throughout the test.</li>
              <li>The test will end if you switch tabs multiple times.</li>
              <li>You have 10 minutes to complete the test.</li>
            </ul>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="agree"
                checked={agreed}
                onChange={() => setAgreed(!agreed)}
                className="form-checkbox mr-2"
              />
              <label htmlFor="agree">I agree to the guidelines</label>
            </div>
            <div className="flex justify-between">
              <button
                onClick={() => navigate(-1)} // Back button logic to navigate to the previous page
                className="px-6 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500"
              >
                Back
              </button>
              <button
                onClick={handleStartTest}
                className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
              >
                Start Test
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Webcam */}
      {showWebcam && !showGuidelines && (
        <div className="absolute top-5 left-5 bg-gray-900 rounded-lg overflow-hidden shadow-lg">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="w-32 h-24"
          />
        </div>
      )}

      {/* Test Container */}
      {!showGuidelines && (
        <div className="w-full max-w-3xl px-8 py-10 bg-white rounded-lg shadow-lg text-gray-900">
          <h2 className="text-4xl font-bold text-center mb-10 text-purple-700">
            {testEnded ? 'Test Completed' : 'Test Attending Mode'}
          </h2>  <button
                onClick={() => navigate(-1)} // Back button logic to navigate to the previous page
                className="px-6 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500"
              >
                Back
              </button>

          {/* Timer */}
          {timeLeft > 0 && !testEnded && (
            <p className="text-center text-lg font-semibold mb-4">
              Time Remaining: <span className="text-red-500">{formatTime(timeLeft)}</span>
            </p>
          )}

          {/* Questions */}
          {!testEnded && (
            <div className="space-y-6">
              {test.map((question, index) => (
                <div key={index} className="p-6 bg-gray-100 rounded-lg shadow-sm">
                  <p className="font-semibold text-lg mb-4 text-gray-800">{index + 1}. {question.text}</p>
                  <div className="space-y-2">
                    {question.options.map((option, optionIndex) => (
                      <div
                        key={optionIndex}
                        className={`flex items-center p-2 rounded-md transition ${
                          question.answered && optionIndex + 1 === question.answeredIndex
                            ? optionIndex + 1 === question.correctAnswer
                              ? 'bg-green-200 text-green-900'
                              : 'bg-red-200 text-red-900'
                            : 'bg-white hover:bg-blue-50'
                        }`}
                      >
                        <input
                          type="radio"
                          id={`q${index}_opt${optionIndex}`}
                          name={`question_${index}`}
                          value={optionIndex + 1}
                          className="form-radio text-blue-500 mr-3"
                          checked={answers[index] === optionIndex + 1}
                          onChange={() => handleOptionSelect(index, optionIndex)}
                          disabled={question.answered}
                        />
                        <label htmlFor={`q${index}_opt${optionIndex}`} className="font-medium">
                          {option}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Submit Button */}
          {!testEnded && (
            <div className="flex justify-center mt-10">
              <button
                onClick={handleSubmit}
                className="px-10 py-3 bg-purple-600 text-white rounded-lg text-lg hover:bg-purple-700"
              >
                Submit Test
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Test;
