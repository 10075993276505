import React, { useState, useRef, useEffect } from "react";
import { PiSnapchatLogoFill } from "react-icons/pi"; // Import the Snapchat icon
import { IoClose } from "react-icons/io5"; // Import the Close icon

const Chatbot = ({ onClose }: { onClose: () => void }) => {
  const [userMessage, setUserMessage] = useState("");
  const [messages, setMessages] = useState([
    {
      text: "Hi there! This is AI Robotic Assistance. How may I help you?",
      type: "incoming",
    },
  ]);
  const [awaitingDetails, setAwaitingDetails] = useState(false);
  const [awaitingScreenshot, setAwaitingScreenshot] = useState(false);
  const [userDetails, setUserDetails] = useState<{ [key: string]: string }>({});
  const [screenshot, setScreenshot] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<string | null>(null); // Added for user feedback

  const chatboxRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTo(0, chatboxRef.current.scrollHeight);
    }
  }, [messages]);

  const handleSendMessage = () => {
    if (!userMessage.trim() && !awaitingScreenshot) return;

    const newMessages = [...messages, { text: userMessage, type: "outgoing" }];
    setMessages(newMessages);
    setUserMessage("");

    if (awaitingDetails) {
      handleDetails(userMessage.trim());
    } else if (awaitingScreenshot && screenshot) {
      handleScreenshotUpload();
    } else if (userMessage.trim().toLowerCase() === "hi") {
      setMessages([
        ...newMessages,
        { text: "Please share your details:\nName:", type: "incoming" },
      ]);
      setAwaitingDetails(true);
    } else {
      setMessages([
        ...newMessages,
        { text: "I'm here to assist you.", type: "incoming" },
      ]);
    }
  };

  const handleDetails = (message: string) => {
    if (!userDetails.name) {
      setUserDetails({ ...userDetails, name: message });
      setMessages((prev) => [
        ...prev,
        { text: "College Name:", type: "incoming" },
      ]);
    } else if (!userDetails.college) {
      setUserDetails({ ...userDetails, college: message });
      setMessages((prev) => [
        ...prev,
        { text: "Email ID:", type: "incoming" },
      ]);
    } else if (!userDetails.usn) {
      setUserDetails({ ...userDetails, usn: message });
      setMessages((prev) => [
        ...prev,
        { text: "USN:", type: "incoming" },
      ]);
    } else if (!userDetails.email) {
      setUserDetails({ ...userDetails, email: message });
      setMessages((prev) => [
        ...prev,
        { text: "Phone Number:", type: "incoming" },
      ]);
    } else if (!userDetails.phone) {
      setUserDetails({ ...userDetails, phone: message });
      setMessages((prev) => [
        ...prev,
        { text: "Describe your problem:", type: "incoming" },
      ]);
    } else if (!userDetails.problemDescription) {
      setUserDetails({ ...userDetails, problemDescription: message });
      setMessages((prev) => [
        ...prev,
        { text: "Thank you for providing the details! You can now upload a screenshot if you want.", type: "incoming" },
      ]);
      setAwaitingScreenshot(true); // Show the screenshot upload option
      setAwaitingDetails(false); // Stop awaiting further details
    }
  };

  const handleScreenshotChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setScreenshot(event.target.files[0]);
    }
  };

  const handleScreenshotUpload = () => {
    if (!screenshot) return;

    const formData = new FormData();
    formData.append("screenshot", screenshot);
    formData.append("name", userDetails.name || "");
    formData.append("college", userDetails.college || "");
    formData.append("usn", userDetails.usn || "");
    formData.append("email", userDetails.email || "");
    formData.append("phone", userDetails.phone || "");
    formData.append("problemDescription", userDetails.problemDescription || "");

    fetch("/api/chatbot/create", { // Updated endpoint based on the backend implementation
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        console.log("Chatbot entry created successfully", data);
        setMessages((prev) => [
          ...prev,
          {
            text: "Thanks for the details. We have sent the issue to the technical team and our team will get back to you in 48 hours.",
            type: "incoming",
          },
        ]);
        setUploadStatus("Upload successful!"); // Feedback on successful upload
      })
      .catch(error => {
        console.error("Error creating chatbot entry", error);
        setUploadStatus("Upload failed. Please try again."); // Feedback on error
      });

    setAwaitingScreenshot(false);
    setScreenshot(null);
  };

  return (
    <div
      className="fixed right-8 bottom-24 bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-200 scale-100 opacity-100"
      style={{ width: "420px" }}
    >
      <header className="bg-teal-600 text-white text-center py-4 relative">
        <h2 className="text-xl font-semibold">Chatbot</h2>
        <IoClose
          className="absolute top-2 right-2 text-white text-2xl cursor-pointer"
          onClick={onClose}
        />
      </header>
      <ul ref={chatboxRef} className="overflow-y-auto h-96 p-6 space-y-4">
        {messages.map((msg, index) => (
          <li
            key={index}
            className={`flex ${msg.type === "outgoing" ? "justify-end" : "justify-start"}`}
          >
            {msg.type === "incoming" && (
              <PiSnapchatLogoFill className="text-teal-600 text-2xl" />
            )}
            <p
              className={`p-3 max-w-xs rounded-lg ${
                msg.type === "outgoing" ? "bg-blue-100 text-black" : "bg-gray-200 text-black"
              }`}
            >
              {msg.text}
            </p>
          </li>
        ))}
      </ul>
      {awaitingScreenshot && (
        <div className="p-4 bg-white border-t">
          <input
            type="file"
            accept="image/*"
            onChange={handleScreenshotChange}
          />
          <button
            onClick={handleScreenshotUpload}
            className="bg-teal-600 text-white px-4 py-2 rounded mt-2"
          >
            Upload Screenshot
          </button>
          {uploadStatus && <p className="mt-2">{uploadStatus}</p>} {/* Display upload status */}
        </div>
      )}
      <div className="flex items-center p-4 bg-white border-t">
        <textarea
          className="w-full p-3 border-none outline-none resize-none text-sm"
          rows={1}
          placeholder="Enter a message..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        ></textarea>
        <span
          className="material-symbols-rounded text-teal-600 text-xl cursor-pointer"
          onClick={handleSendMessage}
        >
          send
        </span>
      </div>
    </div>
  );
};

export default Chatbot;
